import React, { useContext, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { IoBagCheckOutline } from "react-icons/io5";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import emprtCart from '../../assets/images/emptyCart.png';
import thanks from '../../assets/images/thanku.png';


import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { MyContext } from "../../App";
import { fetchDataFromApi,editData, postData, deleteData } from "../../utils/api";
import { useLocation } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import { FaHome } from "react-icons/fa";
const OrderCheckout = () => {
  const [formFieldsF, setFormFieldsF] = useState({
    name: '',
    subCat: '',
    subCatName:'',
    description: '',
    brand: '',
    price: null,
    oldPrice: null,
    catName: '',
    catId: '',
    subCatId: '',
    category: '',
    countInStock: null,
    rating: 0,
    isFeatured: null,
    isNew: null,
    isPopular: null,
    countOrderNumber: 0,
    discount: 0,
    productRam: [],
    size: [],
    color:[],
    productWeight: [],
    location:""
})

  const [formFieldsP, setFormFieldsP] = useState({
    name: '',
    subCat: '',
    subCatName:'',
    description: '',
    brand: '',
    price: null,
    oldPrice: null,
    catName: '',
    catId: '',
    subCatId: '',
    category: '',
    countInStock: null,
    rating: 0,
    isFeatured: null,
    isNew: null,
    isPopular: null,
    countOrderNumber: 0,
    discount: 0,
    productRam: [],
    size: [],
    color:[],
    productWeight: [],
    location:""
})

useEffect(() => {
  window.scrollTo(0, 0);
 

  fetchDataFromApi(`/api/products/${productDirectOrder.productId}`).then((res) => {
     
      setFormFieldsP({
          name: res.name,
          description: res.description,
          brand: res.brand,
          price: res.price,
          oldPrice: res.oldPrice,
          catName: res.catName,
          category: res.category,
          catId: res.catId,
          subCat: res.subCat,
          countInStock: res.countInStock,
          rating: res.rating,
          isFeatured: res.isFeatured,
          isNew: res.isNew,
          isPopular: res.isPopular,
          countOrderNumber: res.countOrderNumber + 1,
          discount: res.discount,
          productRam: res.productRam,
          size: res.size,
          color: res.color,
          productWeight: res.productWeight,
          location:res.location
      });

      setFormFieldsF({
        name: res.name,
        description: res.description,
        brand: res.brand,
        price: res.price,
        oldPrice: res.oldPrice,
        catName: res.catName,
        category: res.category,
        catId: res.catId,
        subCat: res.subCat,
        countInStock: res.countInStock,
        rating: res.rating,
        isFeatured: res.isFeatured,
        isNew: res.isNew,
        isPopular: true,
        countOrderNumber: res.countOrderNumber + 1,
        discount: res.discount,
        productRam: res.productRam,
        size: res.size,
        color: res.color,
        productWeight: res.productWeight,
        location:res.location
    });

  });

}, []);











  const [province, setprovince] = useState('');
  const location = useLocation();
  const { productDirectOrder } = location.state || {};
  const [openDialog, setOpenDialog] = useState(false); // Manage the dialog state

  const handleDialogClose = () => {
    setOpenDialog(false);
    history('/orders', { replace: true }); // Navigate to orders page when dialog is closed
  };

  const handleChange = (event) => {
    setprovince(event.target.value);
  };


  const [formFields, setFormFields] = useState({
    name: "",
    country: "",
    streetAddressLine1: "",
    streetAddressLine2: "",
    city: "",
    state: "",
    zipCode: "",
    phone: "",
    email: "",
  });

//   const [cartData, setCartData] = useState([]);
  const [totalAmount, setTotalAmount] = useState();


  useEffect(() => {
     setTotalAmount(
           parseInt(productDirectOrder.price) * productDirectOrder.quantity)
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

  
    const user = JSON.parse(localStorage.getItem("user"));

    fetchDataFromApi(`/api/user/${user?.userId}`).then((res) => {
      // setUserData(res);
      // setPreviews(res.images);

      setFormFields({

        name: res.name,
        email: res.email,
        phone: res.phone,
        country : res.country,
        streetAddressLine1 : res.streetAddressLine1,
       streetAddressLine2 : res.streetAddressLine2 ,
        city : res.city ,
      state : res.state ,
      zipCode : res.zipCode ,
      });
    });
  }, []);

  const changeInput = (e) => {
    setFormFields(() => ({
      ...formFields,
      [e.target.name]: e.target.value,
    }));
  };



  const onChangeInput = (e) => {
    setFormFields(() => ({
      ...formFields,
      [e.target.name]: e.target.value,
    }));
  };

  const context = useContext(MyContext);
  const history = useNavigate();



const checkout = (e) => {


  e.preventDefault();

  const user = JSON.parse(localStorage.getItem("user"));
  // Validation checks
  if (formFields.name === "") {
    context.setAlertBox({
      open: true,
      error: true,
      msg: "Please fill full name ",
    });
    return false;
  }

  if (formFields.country === "") {
    context.setAlertBox({
      open: true,
      error: true,
      msg: "Please fill country ",
    });
    return false;
  }

  if (formFields.streetAddressLine1 === "") {
    context.setAlertBox({
      open: true,
      error: true,
      msg: "Please fill Street address",
    });
    return false;
  }

  if (formFields.city === "") {
    context.setAlertBox({
      open: true,
      error: true,
      msg: "Please fill city ",
    });
    return false;
  }

  if (formFields.zipCode === "") {
    context.setAlertBox({
      open: true,
      error: true,
      msg: "Please fill zipCode ",
    });
    return false;
  }

  if (formFields.phone === "") {
    context.setAlertBox({
      open: true,
      error: true,
      msg: "Please fill phone Number ",
    });
    return false;
  }
  if (formFields.state === "") {
    context.setAlertBox({
      open: true,
      error: true,
      msg: "Please fill State ",
    });
    return false;
  }

  if (formFields.email === "") {
    context.setAlertBox({
      open: true,
      error: true,
      msg: "Please fill email",
    });
    return false;
  }

  // Further validation checks...
  
  // Order details
  const addressInfo = {
    name: formFields.name,
    phone: formFields.phone,
    address: formFields.streetAddressLine1 + formFields.streetAddressLine2,
    pincode: formFields.zipCode,
    date: new Date()
  };
  const payLoad = {
    name: addressInfo.name,
    phone: formFields.phone,
    address: addressInfo.address,
    pincode: addressInfo.pincode,
    amount: parseInt(totalAmount), // Ensure this is correct
    email: user.email,
    userid: user.userId,
    products: [productDirectOrder],
    date: addressInfo.date,
  };

  // Post order
  postData(`/api/orders/create`, payLoad).then((res) => {
    
 
           
if(formFieldsP.countOrderNumber >= 5){
 
  editData(`/api/products/${productDirectOrder.productId}`, formFieldsF).then((res) => {
    context.setAlertBox({
        open: true,
        msg: 'The product is updatedFF!',
        error: false
    });

})
}else{
  editData(`/api/products/${productDirectOrder.productId}`, formFieldsP).then((res) => {
    context.setAlertBox({
        open: true,
        msg: 'The product is updated!',
        error: false
    });

})
}
          



      

    fetchDataFromApi(`/api/cart/user/${user?.userId}`).then((res) => {
      res?.length !== 0 &&
        res?.map((item) => {
          deleteData(`/api/cart/${item?.id}`).then((res) => {});
        });
      setTimeout(() => {
        context.getCartData();
      }, 1000);
    });
    
    
    setOpenDialog(true); // Open the Thank You dialog
  });

 
};

  return (

    <div>
    { productDirectOrder?.length !== 0 ? (
      <section className="section">
      <div className="container">
      <form className="checkoutForm" onSubmit={checkout}>
        <div className="row">
          <div className="col-md-8">
            <h2 className="hd">BILLING DETAILS</h2>

            <div className="row mt-3">
              <div className="col-md-6">
                <div className="form-group">
                  <TextField
                    label="Full Name *"
                    variant="outlined"
                    className="w-100"
                    size="small"
                    name="name"
                    onChange={onChangeInput}
                    value={formFields.name}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <TextField
                    label="Country *"
                    variant="outlined"
                    className="w-100"
                    size="small"
                    name="country"
                    value={formFields.country}
                    onChange={onChangeInput}
                  />
                </div>
              </div>
            </div>

            <h6>Street address *</h6>

            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <TextField
                    label="House number and street name"
                    variant="outlined"
                    className="w-100"
                    size="small"
                    name="streetAddressLine1"
                    value={formFields.streetAddressLine1}

                    onChange={onChangeInput}
                  />
                </div>

                <div className="form-group">
                  <TextField
                    label="Apartment, suite, unit, etc. (optional)"
                    variant="outlined"
                    className="w-100"
                    size="small"
                    name="streetAddressLine2"
                    value={formFields.streetAddressLine2}
                    
                    onChange={onChangeInput}
                  />
                </div>
              </div>
            </div>

            <h6>Town / City *</h6>

            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <TextField
                    label="City"
                    variant="outlined"
                    className="w-100"
                    size="small"
                    name="city"
                    value={formFields.city}

                    onChange={onChangeInput}
                  />
                </div>
              </div>
            </div>

            <h6>Provinces</h6>

            <div className="row">
              <div className="col-md-12">
                <div className="form-group">

                  <TextField
                    label="State"
                    variant="outlined"
                    className="w-100"
                    size="small"
                    name="state"
                    value={formFields.state}
                    onChange={onChangeInput}
                  />

                
                </div>
              </div>
            </div>

            <h6>Postcode / ZIP *</h6>

            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <TextField
                    label="ZIP Code"
                    variant="outlined"
                    className="w-100"
                    size="small"
                    name="zipCode"
                    value={formFields.zipCode}

                    onChange={onChangeInput}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <TextField
                    label="Phone Number"
                    variant="outlined"
                    className="w-100"
                    size="small"
                    name="phone"
                    value={formFields.phone}

                    onChange={onChangeInput}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <TextField
                    label="Email Address"
                    variant="outlined"
                    className="w-100"
                    size="small"
                    name="email"
                    disabled
                    value={formFields.email}

                    onChange={onChangeInput}
                  />
                </div>
              </div>
            </div>
          </div>


          <div className="col-md-4">
            <div className="card border p-3 whitecolor cartDetails">
                <h4> TOTAL PAYMENT</h4>
                <div className="d-flex align-items-center mb-3">  
                    <span>Product Title</span>
                    <span className="ml-auto ">
                        <b>{productDirectOrder?.productTitle?.substr(0,20) + "..."}</b>
                        {/* {props?.item?.name?.substr(0, 30) + '...'} */}
                    </span>
                </div>
                <div className="d-flex align-items-center mb-3">
                    <span>PRICE</span>
                    <span className="ml-auto  font-weight-bold">
                        <b>{productDirectOrder?.price}</b>
                    </span>
                </div>


                <div className="d-flex align-items-center mb-3">
                    <span>Shipping Charges</span>
                    <span className="ml-auto  ">
                        <b>FREE</b>
                    </span>
                </div>
                

                <div className="d-flex align-items-center mb-3">
                    <span>Quantity</span>
                    <span className="ml-auto  font-weight-bold">
                        <b>{productDirectOrder?.quantity}</b>
                    </span>
                </div>

               
                <div className="d-flex align-items-center">
                    <span>Total</span>
                    <span className="ml-auto font-weight-bold">
                        <b>{productDirectOrder?.subTotal}</b>
                    </span>
                </div>

                <br />
              
                <Button
                    type="submit"
                    className="btnspecialborderspersonal btn-lg btn-big smallbtnonmobilepersonal"
                >
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <IoBagCheckOutline /> &nbsp; Submit Order
                </Button>
               
            </div>
         
        </div>
        </div>
      </form>

      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
      >
       
        <DialogContent>
        <div className=" d-flex align-items-center justify-content-center flex-column">
      <img src={thanks} width="300" />
    
  </div>  
         <h1 className="thankuh1">   Your order has been placed successfully.</h1>
         
        <div className="thankudialogeafterordersubmit ">
          <Link to="/"> <Button className='changestyleorderbtn respon colorbtnmy  btn-round'> Continue Shopping</Button></Link> 
          <Button  onClick={handleDialogClose} className=' colorbtnmy changestyleorderbtn respon btn-round'> Go TO Orders Page</Button> 
          </div> 

          
        </DialogContent>
       
      </Dialog>

    </div>
    </section>
    ) : (
      <div className="empty d-flex align-items-center justify-content-center flex-column">
      <img src={emprtCart} width="150" />
      <h3>Your Cart is currently empty</h3>
      <br />
      <Link to="/"> <Button className='btn-blue colorbtnmy btn-lg btn-big btn-round'><FaHome /> &nbsp; Continue Shopping</Button></Link>
  </div>
    )}
  </div>


   
  )
  
};

export default OrderCheckout;
