import HomeBanner from "../../Components/HomeBanner";
import banner1 from "../../assets/images/banner1.jpg";
import banner2 from "../../assets/images/banner2.jpg";
import Button from "@mui/material/Button";
import { IoIosArrowRoundForward } from "react-icons/io";
import React, { useContext, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import ProductItem from "../../Components/ProductItem";
import NEWProductItem from "../../Components/newproductitems";
import PopularProductItem from "../../Components/popularproducts";

import HomeCat from "../../Components/HomeCat";
import logo from "../../assets/images/logo.png"
import { MyContext } from "../../App";
import { fetchDataFromApi } from "../../utils/api";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CircularProgress from "@mui/material/CircularProgress";

import Banners from "../../Components/banners";
import { Link } from "react-router-dom";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import CheckBanner from "../../Components/checkbanner";

const Home = () => {
  const [openDialog, setOpenDialog] = useState(false); 
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [selectedCat, setselectedCat] = useState();
  const [filterData, setFilterData] = useState([]);
  const [homeSlides, setHomeSlides] = useState([]);
  const [categories , setcategories] = useState([]);

  const [value, setValue] = React.useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [bannerList, setBannerList] = useState([]);
  const [randomCatProducts, setRandomCatProducts] = useState([]);
  const [homeSideBanners, setHomeSideBanners] = useState([]);
  const [homeBottomBanners, setHomeBottomBanners] = useState([]);

  const context = useContext(MyContext);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClose = () => {
    setOpenDialog(false);
  };

  const selectCat = (cat) => {
    setselectedCat(cat);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    context.setisHeaderFooterShow(true);
    setselectedCat(context.categoryData[0]?.name);

    const location = localStorage.getItem("location");

    if (location !== null && location !== "" && location !== undefined) {
      fetchDataFromApi(`/api/products/featured?location=${location}`).then(
        (res) => {
          setFeaturedProducts(res);
        }
      );

      // fetchDataFromApi(
      //   `/api/products?page=1&perPage=8&location=${location}`
      // ).then((res) => {
      //   setProductsData(res);
      // });
      fetchDataFromApi(
        `/api/products`
      ).then((res) => {
        setProductsData(res);
      });
    }

    fetchDataFromApi("/api/homeBanner").then((res) => {
      setHomeSlides(res);
      // setHomeSlides(JSON.parse(res[0].images));
    });
    fetchDataFromApi("/api/banners").then((res) => {
      setBannerList(res.data);
    });

    fetchDataFromApi("/api/homeSideBanners").then((res) => {

      setHomeSideBanners(res.data);
    });

    fetchDataFromApi("/api/homeBottomBanners").then((res) => {

      setHomeBottomBanners(res.data);
    });
    fetchDataFromApi("/api/category").then((res) => {

      setcategories(res);
    });
  }, []);

  useEffect(() => {
    if (context.categoryData?.length > 0) {
      // Initialize an array to hold categories with products
      const allCategoriesWithProducts = [];
  
      // Fetch products for each category
      context.categoryData.forEach((category, index) => {
        fetchDataFromApi(
          `/api/products/catId?catId=${category?.id}&location=${localStorage.getItem("location")}`
        ).then((res) => {
          // Add category and its products to the array
          allCategoriesWithProducts.push({
            catName: category?.name,
            catId: category?.id,
            products: res?.products || [],
          });
  
          // Sort categories by index to maintain order
          allCategoriesWithProducts.sort((a, b) => 
            context.categoryData.findIndex(cat => cat.id === a.catId) -
            context.categoryData.findIndex(cat => cat.id === b.catId)
          );
  
          // Update state after all categories are fetched
          if (index === context.categoryData.length - 1) {
            setRandomCatProducts(allCategoriesWithProducts);
          }
        });
      });
    }
  }, [context.categoryData]);
  
  useEffect(() => {
    if (selectedCat !== undefined) {
      setIsLoading(true);
      const location = localStorage.getItem("location");
      fetchDataFromApi(
        `/api/products/catName?catName=${selectedCat}&location=${location}`
      ).then((res) => {
        setFilterData(res.products);
        setIsLoading(false);
      });
    }
  }, [selectedCat]);



  useEffect(() => {
    window.scrollTo(0, 0);
    context.setisHeaderFooterShow(true);
    setselectedCat(context.categoryData[0]?.name);

    const location = localStorage.getItem("location");

    // Show dialog when page is loaded or user logs in for the first time
    const isFirstVisit = localStorage.getItem("isFirstVisit");
    if (!isFirstVisit) {
      setOpenDialog(true);
      localStorage.setItem("isFirstVisit", "false"); // Set flag after showing dialog
    }

    // if (location !== null && location !== "" && location !== undefined) {
    //   fetchDataFromApi(`/api/products/featured?location=${location}`).then(
    //     (res) => {
    //       setFeaturedProducts(res);
    //     }
    //   );

    //   fetchDataFromApi(
    //     `/api/products?page=1&perPage=8&location=${location}`
    //   ).then((res) => {
    //     setProductsData(res);
    //   });
    // }

    fetchDataFromApi("/api/homeBanner").then((res) => {
      setHomeSlides(res);
    });

  }, []);
  
  return (
    <>
 <Dialog open={openDialog} onClose={handleClose}>
        <DialogContent>
        <div class="containerer">
        <img src={logo}  alt="Welcome Image"/>
        <h1>Wonzilla Welcomes You To A Market Of Truth</h1>
        <p>You are requested to recite the prayer before entering the market.</p>
        <div class="dua">
        اللّهُمَّ إِنِّي أَسْأَلُكَ خَيْرَ هَذَا السُّوقِ وَخَيْرَ مَا فِيهِ، وَأَعُوذُ بِكَ مِنْ شَرِّهِ وَشَرِّ مَا فِيهِ،<br/>
        اللّهُمَّ إِنِّي أَعُوذُ بِكَ أَنْ أُصِيبَ فِيهِ يَمِينًا فَاجِرَةً أَوْ صَفَقَةً خَاسِرَةً۔
    </div>
        <Button onClick={handleClose} className="mt-4 colorbtnmy" >
        Enter the market
          </Button>
    </div>
        </DialogContent>
        
      </Dialog>
      






      {/* {homeSlides?.length !== 0 ? (
        <HomeBanner data={homeSlides} />
      ) : (
        <div className="container mt-3">
          <div className="homeBannerSection">
            <img src={""} alt="homeslideimage" className="w-100" />
          </div>
        </div>
      )} */}

      {homeSlides?.length !== 0 ? (
        <CheckBanner data={homeSlides} />
      ) : (
        <div className="container mt-3">
          <div className="homeBannerSection">
            <img src={""} alt="homeslideimage" className="w-100" />
          </div>
        </div>
      )}


      {context.categoryData?.length !== 0 && (
        <HomeCat catData={context.categoryData} />
      )}

      <section className="homeProducts pb-0">
        <div className="container">
          <div className="row homeProductsRow">
            <div className="col-md-3">
              <div className="sticky">
                {/* {homeSideBanners?.length !== 0 &&
                  homeSideBanners?.map((item, index) => {
                    return (
                      <div className="banner mb-3" key={index}>
                        {item?.subCatId !== null ? (
                          <Link
                            to={`/products/subCat/${item?.subCatId}`}
                            className="box"
                          >
                            <img
                              src={JSON.parse(item?.images[index])}
                              className="w-100 transition"
                              alt="banner img"
                            />
                          </Link>
                        ) : (
                          <Link
                            to={`/products/category/${item?.catId}`}
                            className="box"
                          >
                            <img
                              src={JSON.parse(item?.images[0])}
                              className="cursor w-100 transition"
                              alt="banner img"
                            />
                          </Link>
                        )}
                      </div>
                    );
                  })} */}
                  {homeSideBanners?.length !== 0 &&
  homeSideBanners?.map((item, index) => {
    const imagesArray = JSON.parse(item?.images); // Pehle images ko parse karo

    return (
      <div className="banner mb-3" key={index}>
        {item?.subCatId !== null ? (
          <Link to={`/products/subCat/${item?.subCatId}`} className="box">
            <img
              src={imagesArray[0]} // Pehle element ko access karo
              className="w-100 transition"
              alt="banner img"
            />
          </Link>
        ) : (
          <Link to={`/products/category/${item?.catId}`} className="box">
            <img
              src={imagesArray[0]} // Pehle element ko access karo
              className="cursor w-100 transition"
              alt="banner img"
            />
          </Link>
        )}
      </div>
    );
  })}
              </div>
            </div>

            <div className="col-md-9 productRow">


              <div className="d-flex align-items-center mt-2">
                <div className="info w-75">
                  <h3 className="mb-0 hd">NEW & POPULAR PRODUCTS</h3>
                  <p className="text-light text-sml mb-0">
                    New products with updated stocks.
                  </p>
                </div>

                <div
                  className="ml-auto d-flex align-items-center justify-content-end res-full"
                  style={{ width: "65%" }}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    className="filterTabs"
                  >
                    {context.categoryData?.map((item, index) => {
                      return (
                        <Tab
                        key={index}
                          className="item"
                          label={item.name}
                          onClick={() => selectCat(item.name)}
                        />
                      );
                    })}
                  </Tabs>
                </div>
              </div>

              {productsData?.products?.length === 0 && (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ minHeight: "300px" }}
                >
                  <CircularProgress />
                </div>
              )}

              <div className="product_row productRow2 w-100 mt-4 d-flex productScroller ml-0 mr-0">
                {productsData?.products?.length !== 0 &&
                  productsData?.products
                    ?.slice(0)
                    .reverse()
                    .map((item, index) => {
                      return <ProductItem key={index} item={item} />;
                    })}
              </div>

              {bannerList?.length !== 0 && (
                <Banners data={bannerList} col={3} />
              )}

           
            </div>



          </div>

          {bannerList?.length !== 0 && (
            <Banners data={homeBottomBanners} col={3} />
          )}
        </div>
      </section>
<div className="container">
  {randomCatProducts?.length > 0 ? (
    randomCatProducts
      .filter((category) => category.products?.length > 0) // Filter categories with products
      .map((category, catIndex) => (
        <div key={catIndex} className="categorySection">
          <div className="d-flex align-items-center mt-1 pr-3">
            <div className="info">
              <h3 className="mb-0 hd">{category.catName}</h3>
              <p className="text-light text-sml mb-0">
                Explore products from {category.catName}.
              </p>
            </div>

            <Link
              to={`/products/category/${category.catId}`}
              className="ml-auto"
            >
              <Button className="viewAllBtn">
                View All <IoIosArrowRoundForward />
              </Button>
            </Link>
          </div>

          <div className="product_row category_data w-100 mt-2 d-flex flex-wrap">
            {category.products.map((item, index) => (
              <div
                key={index}
                className="productItem"
                style={{
                  width: "19.2%",
                  margin: "5px",
                  boxSizing: "border-box",
                }}
              >
                <ProductItem item={item} />
              </div>
            ))}
          </div>
        </div>
      ))
  ) : (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "300px" }}
    >
      <CircularProgress />
    </div>
  )}
</div>


    </>
  );
};

export default Home;
