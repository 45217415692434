import React, { useContext, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { Link } from "react-router-dom";

import { MyContext } from "../../App";
import "../../App.css"

const HomeCat = (props) => {

    const context = useContext(MyContext);

    return (
        <section className="homeCat pb-2">
            <div className="container">
                
                <h3 className="mb-3 hd">Categories</h3>
                <Swiper
                    slidesPerView={8} 
                    spaceBetween={0}
                    navigation={context.windowWidth>992 ? true : false}
                    slidesPerGroup={context.windowWidth>992 ? 3 : 1}
                    modules={[Navigation]}
                 
                    loop={false}
                    className="mySwiper category-swiper"
                    breakpoints={{
                        320: {
                            slidesPerView: 5,
                            spaceBetween: 10,
                          },
                        500: {
                          slidesPerView: 5,
                          spaceBetween: 10,
                        },
                        768: {
                          slidesPerView: 8,
                          spaceBetween: 10,
                        }
                      }}
                  
                >



                    {
                        props.catData?.length !== 0 && props.catData?.map((cat, index) => {
                            return (
                                <SwiperSlide className="category-swiper"    style={{
                                    width: "80px" 
                                }}  key={index}>
                                    <Link to={`/products/category/${cat.id}`}>
                                        <div className="item text-center cursor" style={{ background: cat.color }}>
                                            <img src={JSON.parse(cat.image)} alt="catimages"/>
                                        </div>
                                        <h6>{cat.name}</h6>
                                    </Link>
                                </SwiperSlide>
                            )
                        })
                    }



                </Swiper>
            </div>
        </section>
    )
}

export default HomeCat;