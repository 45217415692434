import React, { useContext } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { MyContext } from "../../App";
import { Link } from "react-router-dom";
import "../../App.css";

const Banners = (props) => {
  const context = useContext(MyContext);
  console.log(props.data)

return (
  <>
    {/* <div className="bannerAds pt-3 pb-3">
      {context?.windowWidth > 992 ? (
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          loop={true}
          navigation={false}
          slidesPerGroup={3}
          modules={[Navigation]}
          className="bannerSection homebottombanners pt-3"
          breakpoints={{
            300: { slidesPerView: 1, spaceBetween: 10 },
            400: { slidesPerView: 1, spaceBetween: 10 },
            600: { slidesPerView: 1, spaceBetween: 10 },
            750: { slidesPerView: 1, spaceBetween: 10 },
          }}
        >
          {props?.data?.length !== 0 &&
            props?.data?.map((item, index) => {
              // ✅ Pehle images ko JSON parse karna zaroori hai
              const imagesArray = JSON.parse(item?.images || "[]"); // Agar empty ho to default []

              return (
                <SwiperSlide key={index}>
                  <div className={`col_`}>
                    {item?.subCatId !== null ? (
                      <Link to={`/products/subCat/${item?.subCatId}`} className="box">
                        <img
                          src={imagesArray[0]} // ✅ Pehla image URL lein
                          className="w-100 transition"
                          alt="banner img"
                        />
                      </Link>
                    ) : (
                      <Link to={`/products/category/${item?.catId}`} className="box">
                        <img
                          src={imagesArray[0]} // ✅ Pehla image URL lein
                          className="w-100 transition"
                          alt="banner img"
                        />
                      </Link>
                    )}
                  </div>
                </SwiperSlide>
              );
            })}
        </Swiper>
      ) : (
        <div
          className="bannerSection pt-3"
          style={{ gridTemplateColumns: `repeat(${props?.col},1fr)` }}
        >
          {props?.data?.length !== 0 &&
            props?.data?.map((item, index) => {
              // ✅ Yahan bhi images ko JSON parse karna hoga
              const imagesArray = JSON.parse(item?.images || "[]");

              return (
                <div className={`col_`} key={index}>
                  {item?.subCatId !== null ? (
                    <Link to={`/products/subCat/${item?.subCatId}`} className="box">
                      <img
                        src={imagesArray[0]} // ✅ Pehla image URL lein
                        className="w-100 transition"
                        alt="banner img"
                      />
                    </Link>
                  ) : (
                    <Link to={`/products/category/${item?.catId}`} className="box">
                      <img
                        src={imagesArray[0]} // ✅ Pehla image URL lein
                        className="w-100 transition"
                        alt="banner img"
                      />
                    </Link>
                  )}
                </div>
              );
            })}
        </div>
      )}
    </div> */}
  </>
);


  // return (
  //   <>
  //     <div className="bannerAds pt-3 pb-3">
  //       {context?.windowWidth > 992 ? (
  //         <Swiper
  //           slidesPerView={1}
  //           spaceBetween={10}
  //           loop={true}
  //           navigation={false}
  //           slidesPerGroup={3}
  //           modules={[Navigation]}
  //           className="bannerSection pt-3"
  //           breakpoints={{
  //             300: {
  //               slidesPerView: 1,
  //               spaceBetween: 10,
  //             },
  //             400: {
  //               slidesPerView: 1,
  //               spaceBetween: 10,
  //             },
  //             600: {
  //               slidesPerView: 1,
  //               spaceBetween: 10,
  //             },
  //             750: {
  //               slidesPerView: 1,
  //               spaceBetween: 10,
  //             },
  //           }}
  //         >
  //             {props?.data?.length !== 0 &&
  // props?.data?.map((item, index) => {
  //   const imagesArray = JSON.parse(item?.images); // Pehle JSON parse karo

  //   return (
  //     <SwiperSlide key={index}>
  //       <div className={`col_`}>
  //         {item?.subCatId !== null ? (
  //           <Link to={`/products/subCat/${item?.subCatId}`} className="box">
  //             <img
  //               src={imagesArray[0]} // Pehla image URL lo
  //               className="w-100 transition"
  //               alt="banner img"
  //             />
  //           </Link>
  //         ) : (
  //           <Link to={`/products/category/${item?.catId}`} className="box">
  //             <img
  //               src={imagesArray[0]} // Pehla image URL lo
  //               className="w-100 transition"
  //               alt="banner img"
  //             />
  //           </Link>
  //         )}
  //       </div>
  //     </SwiperSlide>
  //   );
  // })}

  //         </Swiper>
  //       ) : (
  //         <div
  //           className="bannerSection pt-3"
  //           style={{ gridTemplateColumns: `repeat(${props?.col},1fr)` }}
  //         >
  //           {props?.data?.length !== 0 &&
  //             props?.data?.map((item, index) => {
  //               return (
  //                  <div className={`col_`}>
  //                     {item?.subCatId !== null ? (
  //                       <Link
  //                         to={`/products/subCat/${item?.subCatId}`}
  //                         className="box"
  //                       >
  //                         <img
  //                           src={item?.images[0]}
  //                           className="w-100 transition"
  //                           alt="banner img"
  //                         />
  //                       </Link>
  //                     ) : (
  //                       <Link
  //                         to={`/products/category/${item?.catId}`}
  //                         className="box"
  //                       >
  //                         <img
  //                           src={item?.images[0]}
  //                           className="w-100 transition"
  //                           alt="banner img"
  //                         />
  //                       </Link>
  //                     )}
  //                   </div>
  //               );
  //             })}
  //         </div>
  //       )}
  //     </div>
  //   </>
  // );
};

export default Banners;



// import React, { useContext } from "react";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { MyContext } from "../../App";
// import { Link } from "react-router-dom";
//   import { Navigation, Pagination } from "swiper/modules";
//   import "swiper/css";
//   import "swiper/css/navigation";
//   import "swiper/css/pagination";
//   import "../checkbanner/Banner.css";
// const Banners = (props) => {
//   const context = useContext(MyContext);
//   return (
//     <>
//       <div className="banner-container">
//         {context?.windowWidth > 992 ? (
//           <Swiper
//           slidesPerView={1} // Center banner + Side Previews
//           spaceBetween={10}
//           loop={true}
//           navigation={true}
//           pagination={{ clickable: true }}
//           modules={[Navigation, Pagination]}
//           centeredSlides={true} // Center main hoga
//           breakpoints={{
//             768: {
//               slidesPerView: 1.5, // Tablet pe side preview zyada dikhayega
//             },
//             1024: {
//               slidesPerView: 1.8, // Desktop pe aur zyada preview dikhayega
//             },
//           }}
//           className="swiper-container"
//         >
//               {props?.data?.length !== 0 &&
//   props?.data?.map((item, index) => {
//     const imagesArray = JSON.parse(item?.images); // Pehle JSON parse karo

//     return (
//       <SwiperSlide key={index} className="swiper-slide">
   
//           {item?.subCatId !== null ? (
//             <Link to={`/products/subCat/${item?.subCatId}`} className="box">
//               <img
//                 src={imagesArray[0]} // Pehla image URL lo
//                 className="w-100 transition"
//                 alt="banner img"
//               />
//             </Link>
//           ) : (
//             <Link to={`/products/category/${item?.catId}`} className="box">
//               <img
//                 src={imagesArray[0]} // Pehla image URL lo
//                 className="w-100 transition"
//                 alt="banner img"
//               />
//             </Link>
//           )}
//       </SwiperSlide>
//     );
//   })}

//           </Swiper>
//         ) : (
//           <div
//             className="bannerSection pt-3"
//             style={{ gridTemplateColumns: `repeat(${props?.col},1fr)` }}
//           >
//             {props?.data?.length !== 0 &&
//               props?.data?.map((item, index) => {
//                 return (
//                    <div className={`col_`}>
//                       {item?.subCatId !== null ? (
//                         <Link
//                           to={`/products/subCat/${item?.subCatId}`}
//                           className="box"
//                         >
//                           <img
//                             src={item?.images[0]}
//                             className="w-100 transition"
//                             alt="banner img"
//                           />
//                         </Link>
//                       ) : (
//                         <Link
//                           to={`/products/category/${item?.catId}`}
//                           className="box"
//                         >
//                           <img
//                             src={item?.images[0]}
//                             className="w-100 transition"
//                             alt="banner img"
//                           />
//                         </Link>
//                       )}
//                     </div>
//                 );
//               })}
//           </div>
//         )}
//       </div>
//     </>
//   );
// };

// export default Banners;



  // import React from "react";
  // import { Swiper, SwiperSlide } from "swiper/react";
  // import { Navigation, Pagination } from "swiper/modules";
  // import "swiper/css";
  // import "swiper/css/navigation";
  // import "swiper/css/pagination";
  // import "../checkbanner/Banner.css";
  
  // const Banners = () => {

  //   const banners = [
  //       "https://res.cloudinary.com/dm02wcpvm/image/upload/v1742850437/1742850435136_Cashback_Service_Colorful_Facebook_Cover_Photo.png",
  //       "https://res.cloudinary.com/dm02wcpvm/image/upload/v1742850437/1742850435136_Cashback_Service_Colorful_Facebook_Cover_Photo.png",
  //       "https://res.cloudinary.com/dm02wcpvm/image/upload/v1742850437/1742850435136_Cashback_Service_Colorful_Facebook_Cover_Photo.png",
  //       "https://res.cloudinary.com/dm02wcpvm/image/upload/v1742850437/1742850435136_Cashback_Service_Colorful_Facebook_Cover_Photo.png",
  //       "https://res.cloudinary.com/dm02wcpvm/image/upload/v1742850437/1742850435136_Cashback_Service_Colorful_Facebook_Cover_Photo.png",
  //     ];
  
  //   return (
  //     <div className="banner-container">
  //       <Swiper
  //         slidesPerView={3} // Center banner + Side Previews
  //         spaceBetween={10}
  //         loop={true}
  //         navigation={true}
  //         pagination={{ clickable: true }}
  //         modules={[Navigation, Pagination]}
  //         centeredSlides={true} // Center main hoga
  //         breakpoints={{
  //           768: {
  //             slidesPerView: 1.5, // Tablet pe side preview zyada dikhayega
  //           },
  //           1024: {
  //             slidesPerView: 1.8, // Desktop pe aur zyada preview dikhayega
  //           },
  //         }}
  //         className="swiper-container"
  //       >
  //         {banners.map((image, index) => (
  //           <SwiperSlide key={index} className="swiper-slide">
  //             <img src={image} alt={`Banner ${index + 1}`} className="banner-img" />
  //           </SwiperSlide>
  //         ))}
  //       </Swiper>
  //     </div>
  //   );
  // };
  
  // export default Banners;