import { Link } from "react-router-dom";
import Rating from '@mui/material/Rating';
import QuantityBox from "../../Components/QuantityBox";
import { IoIosClose } from "react-icons/io";
import Button from '@mui/material/Button';

import emprtCart from '../../assets/images/emptyCart.png';
import { MyContext } from "../../App";
import { useContext, useEffect, useState } from "react";
import { deleteData, editData, fetchDataFromApi } from "../../utils/api";
import { IoBagCheckOutline } from "react-icons/io5";
import { FaHome } from "react-icons/fa";
import { loadStripe } from '@stripe/stripe-js';
import { useNavigate } from 'react-router-dom';

const Cart = () => {

    const [cartData, setCartData] = useState([]);
    const [productQuantity, setProductQuantity] = useState();

    const [isLoading, setIsLoading] = useState(false);
    const [selectedQuantity, setselectedQuantity] = useState();
    const [chengeQuantity, setchengeQuantity] = useState(0);
    const [isLogin, setIsLogin] = useState(false);
    const [activeSize, setActiveSize] = useState(null);
    const [activeColor, setActiveColor] = useState(null);
    const [productData, setProductData] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]); // Fo
    const context = useContext(MyContext);
    const history = useNavigate();




    //      // Load initial checkbox state from localStorage
    //   useEffect(() => {
    //     const storedSelectedItems = JSON.parse(localStorage.getItem("selectedCartItems") || "[]");
    //     setSelectedItems(storedSelectedItems);
    //   }, []);

    //   // Save checkbox state to localStorage whenever it changes
    //   useEffect(() => {
    //     localStorage.setItem("selectedCartItems", JSON.stringify(selectedItems));
    //   }, [selectedItems]);


    // Load initial checkbox state from localStorage
    useEffect(() => {
        const storedSelectedItems = JSON.parse(localStorage.getItem("selectedCartItems") || "[]");
        setSelectedItems(storedSelectedItems);
    }, []);

    // Save checkbox state to localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem("selectedCartItems", JSON.stringify(selectedItems)); 
    }, [selectedItems]);



    useEffect(() => {
        window.scrollTo(0, 0)
        const token = localStorage.getItem("token");
        if (token !== "" && token !== undefined && token !== null) {
            setIsLogin(true);
        }
        else {
            history("/signIn");
        }

        const user = JSON.parse(localStorage.getItem("user"));
        fetchDataFromApi(`/api/cart/user/${user?.userId}`).then((res) => {
            setCartData(res);
            setselectedQuantity(res?.quantity)
        })
    }, []);

    const quantity = (val) => {
        setProductQuantity(val);
        setchengeQuantity(val)
    }


    useEffect(() => {
    }, [cartData]);


    const selectedItem = (item, quantityVal) => {
        if (chengeQuantity !== 0) {
            setIsLoading(true);
            const user = JSON.parse(localStorage.getItem("user"));
    
            const updatedcheckedcartFields = {
                productTitle: item?.productTitle,
                image: item?.image,
                rating: item?.rating,
                price: item?.price,
                quantity: quantityVal,
                countOrderNumber: item?.countOrderNumber,
                subTotal: parseInt(item?.price * quantityVal),
                productId: item?.productId ,
                userId: user?.userId,
                size: item?.size, // undefined سے بچنے کے لئے
                color: item?.color, // undefined سے بچنے کے لئے
                isPopular: item?.isPopular || false, // غائب فیلڈ شامل کریں
            };
    
            editData(`/api/cart/${item?.id}`, updatedcheckedcartFields)
                .then((res) => {
                    setTimeout(() => {
                        setIsLoading(false);
                        setCartData((prevCartData) =>
                            prevCartData.map((cartItem) =>
                                cartItem.id === item.id
                                    ? { ...cartItem, quantity: quantityVal, subTotal: parseInt(item?.price * quantityVal) }
                                    : cartItem
                            )
                        );
                    }, 1000);
                })
                .catch((error) => {
                    console.error("سرور سے ایئرر:", error.response?.data);
                    setIsLoading(false);
                });
        }
    };
    const handleCheckboxChange = (item) => {
        if (selectedItems.includes(item.id)) {
            // Remove item if unchecked
            setSelectedItems(selectedItems.filter((id) => id !== item.id));
        } else {
            // Add item if checked
            setSelectedItems([...selectedItems, item.id]);
        }
    };


    const removeItem = (id) => {
        setIsLoading(true);
        deleteData(`/api/cart/${id}`).then((res) => {
            context.setAlertBox({
                open: true,
                error: false,
                msg: "item removed from cart!"
            })

            const user = JSON.parse(localStorage.getItem("user"));
            fetchDataFromApi(`/api/cart/user/${user?.userId}`).then((res) => {
                setCartData((prev) => prev.filter((item) => item.id !== id));
                setSelectedItems((prev) => prev.filter((itemId) => itemId !== id)); // Remove from selected items
                setIsLoading(false);
            })

            context.getCartData();

        })
    }



    // const removeItem = (id) => {
    //     deleteData(`/api/cart/${id}`).then(() => {
    //         setCartData((prev) => prev.filter((item) => item._id !== id));
    //         setSelectedItems((prev) => prev.filter((itemId) => itemId !== id)); // Remove from selected items
    //     });
    // };


    const handleCheckout = () => {
        const selectedCartItems = cartData.filter((item) => selectedItems.includes(item.id));

        // Process selectedCartItems for order placement
        // localStorage.setItem('selectedItems', JSON.stringify(selectedCartItems));
        localStorage.removeItem("selectedItems")
        localStorage.setItem('selectedItems', JSON.stringify(selectedCartItems));
        history('/checkout'); // Navigate to checkout page
    };
    const selectedCartItems = cartData.filter((item) => selectedItems.includes(item.id));

    const selectedSubtotal = selectedCartItems
        .map(item => parseInt(item.price) * item.quantity)
        .reduce((total, value) => total + value, 0);

    // Add shipping charges to the total
    const selectedTotal = selectedSubtotal  // Shipping charges


    return (
        <>

            <section className="section p-1 cartPage">
                <div className="container">
                    <h2 className="hd ">Your Cart</h2>
                    <p>There are <b className="text-danger">{cartData?.length}</b> products in your cart</p>

                    {
                        cartData?.length !== 0 ?

                            <div className="row">
                                <div className="col-md-9 pr-5">

                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>

                                                    <th width="10%">Select ( <b>{selectedCartItems.length}</b> ) </th>
                                                    <th width="30%">Product</th>
                                                    <th width="10%">Color</th>
                                                    <th width="10%">size</th>
                                                    <th width="20%">Quantity</th>
                                                    <th width="10%">Remove</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    cartData?.length !== 0 && cartData?.map((item, index) => {
                                                        return (
                                                            <tr key={item.id}>
                                                                <td className="checkedcartitembox" width="10%" onClick={() => handleCheckboxChange(item)}>

                                                                    <input
                                                                    width="5%"
                                                                        type="checkbox"
                                                                        checked={selectedItems.includes(item.id) }
                                                                        // onChange={() => handleCheckboxChange(item)}
                                                                    />
                                                                </td>
                                                                <td width="30%">
                                                                    <Link to={`/product/${item?.productId}`}>
                                                                        <div className="d-flex align-items-center cartItemimgWrapper">
                                                                            <div className="imgWrapper">
                                                                                <img src={item?.image}
                                                                                    className="w-100" alt={item?.productTitle} />
                                                                            </div>

                                                                            <div className="info px-3">
                                                                                <h6>
                                                                                    {item?.productTitle?.substr(0, 30) + '...'}

                                                                                </h6>
                                                                                <Rating name="read-only" value={item?.rating} readOnly size="small" />
                                                                            </div>

                                                                        </div>
                                                                    </Link>
                                                                </td>
                                                                <td width="10%">{item?.color}</td>
                                                                <td width="10%"> {item?.size}</td>
                                                                <td width="20%">
                                                                    <QuantityBox quantity={quantity} item={item}
                                                                        selectedItem={selectedItem}
                                                                        value={item?.quantity} />
                                                                </td>
                                                                <td width="10%"><span className="remove" onClick={() => removeItem(item?.id)}><IoIosClose /></span></td>
                                                            </tr>
                                                        )
                                                    })
                                                }


                                            </tbody>

                                        </table>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="card border p-3 cartDetails">
                                        <h4>CART TOTALS</h4>
                                        <div className="d-flex align-items-center mb-3">
                                            <span>Total Items</span>
                                            <span className="ml-auto"><b>{context.cartData?.length > 0 ? context.cartData?.length : 0}</b></span>
                                        </div>
                                        <div className="d-flex align-items-center mb-3">
                                            <span>Total Selected Items</span>
                                            <span className="ml-auto">
                                                <b>{selectedCartItems.length}</b>
                                            </span>
                                        </div>
                                        {/* <div className="d-flex align-items-center mb-3">
                                            <span>Subtotal</span>
                                            <span className="ml-auto text-red font-weight-bold">
                                           <b>

{
        (cartData?.length !== 0 
            ? cartData.map(item => parseInt(item.price) * item.quantity)
                .reduce((total, value) => total + value, 0) 
            : 0
        )?.toLocaleString('en-US', { style: 'currency', currency: 'PKR' })
    }
    </b>
                                            </span>
                                        </div> */}

                                        <div className="d-flex align-items-center mb-3">
                                            <span>Subtotal</span>
                                            <span className="ml-auto text-red font-weight-bold">
                                                <b>
                                                    {selectedSubtotal.toLocaleString('en-US', {
                                                        style: 'currency',
                                                        currency: 'PKR',
                                                    })}
                                                </b>
                                            </span>
                                        </div>
                                        <div className="d-flex align-items-center mb-3">
                                            <span>Shipping</span>
                                            <span className="ml-auto"><b>FREE</b></span> {/* یہاں "Free" کو "Rs 199" سے بدل دیا گیا */}
                                        </div>

                                        <div className="d-flex align-items-center mb-3">
                                            <span>Estimate for</span>
                                            <span className="ml-auto"><b>KARACHI</b></span>
                                        </div>


                                        {/* <div className="d-flex align-items-center">
    <span>Total</span>
    <span className="ml-auto text-red font-weight-bold">
   <b>
    {
    (cartData?.length !== 0 
        ? cartData.map(item => parseInt(item.price) * item.quantity)
            .reduce((total, value) => total + value, 0) // + 199 // shipping charges ko reduce ke baad ek martaba add karna
        : 199
    )?.toLocaleString('en-US', { style: 'currency', currency: 'PKR' })
}
</b>

    </span>
</div> */}

                                        <div className="d-flex align-items-center">
                                            <span>Total</span>
                                            <span className="ml-auto text-red font-weight-bold">
                                                <b>
                                                    {selectedTotal.toLocaleString('en-US', {
                                                        style: 'currency',
                                                        currency: 'PKR',
                                                    })}
                                                </b>
                                            </span>
                                        </div>



                                        <br />
                                        {selectedCartItems.length > 0 && (
                                        <Button className='w-100 btnspecialborderspersonal btn-lg btn-big' onClick={handleCheckout}>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            <IoBagCheckOutline /> &nbsp; Checkout</Button>
                                        )}

                                    </div>
                                </div>
                            </div>

                            :


                            <div className="empty d-flex align-items-center justify-content-center flex-column">
                                <img src={emprtCart} width="150" />
                                <h3>Your Cart is currently empty</h3>
                                <br />
                                <Link to="/"> <Button className=' colorbtnmy btn-big btn-round'><FaHome /> &nbsp; Continue Shopping</Button></Link>
                            </div>


                    }


                </div>
            </section>

            {isLoading === true && <div className="loadingOverlay"></div>}


        </>
    )
}

export default Cart;