import Rating from '@mui/material/Rating';
import { TfiFullscreen } from "react-icons/tfi";
import Button from '@mui/material/Button';
import { IoMdHeartEmpty } from "react-icons/io";
import { useContext, useEffect, useRef, useState } from 'react';
import { MyContext } from '../../App';
import { Link } from 'react-router-dom';

import Slider from "react-slick";
import Skeleton from '@mui/material/Skeleton';
import { IoIosImages } from "react-icons/io";
import { fetchDataFromApi, postData } from '../../utils/api';
import { FaHeart } from "react-icons/fa";
import { deleteData, editData } from "../../utils/api";

import "../../App.css"
const ProductItem = (props) => {


    const [isHovered, setIsHovered] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isAddedToMyList, setSsAddedToMyList] = useState(false);
    const [myListData, setmyListData] = useState([]);


    const {
        item,
        itemView,
        badgeType // "new" or "popular"
    } = props;

    const badgeClass = badgeType === "new" ? "badge-new" : badgeType === "popular" ? "badge-popular" : "";

    const context = useContext(MyContext);

    const sliderRef = useRef();

    var settings = {
        dots: true,
        infinite: true,
        loop: true,
        speed: 200,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: 100
    };

    const viewProductDetails = (id) => {
        context.openProductDetailsModal(id, true);
    }

    const handleMouseEnter = (id) => {
        if (isLoading === false) {
            setIsHovered(true);
            setTimeout(() => {
                if (sliderRef.current) {
                    sliderRef.current.slickPlay();
                }
            }, 20);
        }

        const user = JSON.parse(localStorage.getItem("user"));

        fetchDataFromApi(`/api/my-list?productId=${id}&userId=${user?.userId}`).then((res)=>{
            if(res.length!==0){
                setSsAddedToMyList(true);
            }
        })

    }


    const handleMouseLeave = () => {
        if (isLoading === false) {
            setIsHovered(false);
            setTimeout(() => {
                if (sliderRef.current) {
                    sliderRef.current.slickPause();
                }
            }, 20);
        }
    }


    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 500);
    }, []);


    const removeItem = (id) => {
        setIsLoading(true);
        deleteData(`/api/my-list/${id}`).then((res) => {
            context.setAlertBox({
                open: true,
                error: false,
                msg: "item removed from My List!"
            })
    
            const user = JSON.parse(localStorage.getItem("user"));
            fetchDataFromApi(`/api/my-list?userId=${user?.userId}`).then((res) => {
                setmyListData(res);
                setIsLoading(false);
            })
    
        })
    }


    const toggleMyList = async (id) => {
        if (isLoading) return; // If a request is already in progress, don't allow further clicks
    
        setIsLoading(true); // Set loading to true while processing
        const user = JSON.parse(localStorage.getItem("user"));
        
        if (user !== undefined && user !== null && user !== "") {
            try {
                // Check if product is already in My List
                const res = await fetchDataFromApi(`/api/my-list?productId=${id}&userId=${user?.userId}`);
                if (res.length !== 0) {
                    // If product is in My List, remove it
                    await removeItem(res[0].id);
                    setSsAddedToMyList(false);  // Update the heart icon to empty
                } else {
                    // If product is not in My List, add it
                    const data = {
                        productTitle: props?.item?.name,
                        image: props?.item?.images[0],
                        rating: props?.item?.rating,
                        price: props?.item?.price,
                        productId: id,
                        userId: user?.userId,
                    
                    };
                    const response = await postData(`/api/my-list/add/`, data);
                    if (response.status !== false) {
                        context.setAlertBox({
                            open: true,
                            error: false,
                            msg: "Product added to My List",
                        });
                        setSsAddedToMyList(true);  // Update heart icon to filled
                    } else {
                        context.setAlertBox({
                            open: true,
                            error: true,
                            msg: response.msg,
                        });
                    }
                }
            } catch (error) {
                console.log("Error occurred:", error);
                context.setAlertBox({
                    open: true,
                    error: true,
                    msg: "Something went wrong! Please try again.",
                });
            } finally {
                setIsLoading(false); // Reset the loading state after the request is done
            }
        } else {
            context.setAlertBox({
                open: true,
                error: true,
                msg: "Please Login to continue",
            });
            setIsLoading(false); // Reset loading state if user is not logged in
        }
    };


    
    return (
        <>
       
            <div className={`productItem ${props.itemView}`}
                onMouseEnter={()=>handleMouseEnter(props?.itemView === 'recentlyView' ? props.item?.prodId : props.item?.id)}
                onMouseLeave={handleMouseLeave}
            >

                
                <div className="img_rapper">
  <Link to={`/product/${props?.itemView === 'recentlyView' ? props.item?.prodId : props.item?.id}`}>
    <div className='productItemSliderWrapper'>
      {isHovered === true && (
        <Slider {...settings} ref={sliderRef} className='productItemSlider'>
          {JSON.parse(props.item?.images)?.map((image, index) => {
            return (
              <div className='slick-slide' key={index}>
                <img src={image} alt='this is product images' className="w-100" />
              </div>
            );
          })}
        </Slider>
      )}
    </div>

    {isLoading === true ? (
      <Skeleton variant="rectangular" width={300} height={400}>
        <IoIosImages/>
      </Skeleton>
    ) : (
      <img 
        src={JSON.parse(props.item?.images)[0]} 
        alt='this is product images' 
        className="w-100" 
      />
    )}
  </Link>

  <span className="badge badge-primary">{props.item?.discount}%</span>

  <div>
    {props.item?.isNew && <span className="badge-new">New</span>}
    {props.item?.isPopular && <span className="badge-popular">Popular</span>}
  </div>

  <div className="actions">
    <Button onClick={() => viewProductDetails(props?.itemView === 'recentlyView' ? props.item?.prodId : props.item?.id)}>
      <TfiFullscreen />
    </Button>
    
    <Button className={isAddedToMyList ? "active" : ""} onClick={() => toggleMyList(props?.itemView === 'recentlyView' ? props.item?.prodId : props.item?.id)}>
      {isAddedToMyList === true ? <FaHeart style={{ fontSize: '20px' }} /> : <IoMdHeartEmpty style={{ fontSize: '20px' }} />}
    </Button>
  </div>
</div>

                <div className="info">
                    <Link to={`/product/${props?.itemView === 'recentlyView' ? props.item?.prodId : props.item?.id}`}><h4 className='smallchar'>{props?.item?.name?.substr(0, 30) + '...'}</h4></Link>

                    {
                        props?.item?.countInStock>=1 ?  <span className="text-success d-block">In Stock</span>
                        :

                        <span className="text-danger d-block">Out of Stock</span>

                    }
                   
                    <Rating className="mt-2 mb-2" name="read-only" value={props?.item?.rating} readOnly size="small" precision={0.5} />

                    <div className="d-flex">
                        <span className="oldPrice">Rs {props?.item?.oldPrice}</span>
                        <span className="netPrice text-danger ml-2">Rs {props?.item?.price}</span>
                    </div>


                   
                </div>

            </div>




            {/*<ProductModal/> */}
        </>
    )
}

export default ProductItem