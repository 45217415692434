import ProductZoom from "../../Components/ProductZoom";
import Rating from "@mui/material/Rating";
import QuantityBox from "../../Components/QuantityBox";
import Button from "@mui/material/Button";
import { BsCartFill } from "react-icons/bs";
import { useContext, useEffect, useState } from "react";
import { FaRegHeart } from "react-icons/fa";
import { MdOutlineCompareArrows } from "react-icons/md";
import Tooltip from "@mui/material/Tooltip";
import RelatedProducts from "./RelatedProducts";


import { Link, useNavigate, useParams } from "react-router-dom";
import { fetchDataFromApi, postData } from "../../utils/api";
import CircularProgress from "@mui/material/CircularProgress";
import { MyContext } from "../../App";
import { FaHeart } from "react-icons/fa";
import { IoBagCheckOutline } from "react-icons/io5";
import { deleteData, editData } from "../../utils/api";


const ProductDetails = () => {
  const [activeSize, setActiveSize] = useState(null);
  const [activeColor, setActiveColor] = useState(null);
  const [productData, setProductData] = useState([]);
  const [activeTabs, setActiveTabs] = useState(0);
  const [relatedProductData, setRelatedProductData] = useState([]);
  const [recentlyViewdProducts, setRecentlyViewdProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reviewsData, setreviewsData] = useState([]);
  // const [isAddedToMyList, setSsAddedToMyList] = useState(false);
  const [isAddedToMyList, setIsAddedToMyList] = useState(false);
  const [myListData, setmyListData] = useState([]);
  const history = useNavigate();
  let [cartFields, setCartFields] = useState({});
  let [productQuantity, setProductQuantity] = useState();
  const [tabError, setTabError] = useState(false);
  const [cartData, setCartData] = useState([]);
  const [selectedQuantity, setselectedQuantity] = useState();
  const [chengeQuantity, setchengeQuantity] = useState(0);
  const [isLogin,setIsLogin]  = useState(false);
  const [directOrderData,setDirectOrderData]  = useState(false);

  const { id } = useParams();

  const context = useContext(MyContext);

  const isActive = (index) => {
    setActiveSize(index);
    setTabError(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setActiveSize(null);
    fetchDataFromApi(`/api/products/${id}`).then((res) => {
      setProductData(res);

      if (
        res?.productRam.length === 0 &&
        res?.productWeight.length === 0 &&
        res?.size.length === 0 
      ) {
        setActiveSize(1);
      }

      fetchDataFromApi(
        `/api/products/subCatId?subCatId=${res?.subCatId
        }&location=${localStorage.getItem("location")}`
      ).then((res) => {
        const filteredData = res?.products?.filter((item) => item.id !== id);
        setRelatedProductData(filteredData);
      });
    });

    fetchDataFromApi(`/api/productReviews?productId=${id}`).then((res) => {
      setreviewsData(res);
    });

    const user = JSON.parse(localStorage.getItem("user"));

    fetchDataFromApi(
      `/api/my-list?productId=${id}&userId=${user?.userId}`
    ).then((res) => {
      if (res.length !== 0) {
        setIsAddedToMyList(true);
      }
    });
  }, [id]);



  const [rating, setRating] = useState(1);
  const [reviews, setReviews] = useState({
    productId: "",
    customerName: "",
    customerId: "",
    review: "",
    customerRating: 0,
  });

  const onChangeInput = (e) => {
    setReviews(() => ({
      ...reviews,
      [e.target.name]: e.target.value,
    }));
  };

  const changeRating = (e) => {
    setRating(e.target.value);
    reviews.customerRating = e.target.value;
  };


  const addReview = (e) => {
    e.preventDefault();
  
    // Check if the review text is empty
    if (reviews.review.trim() === "") {
      context.setAlertBox({
        open: true,
        error: true,
        msg: "Please write a review",
      });
      return; // Prevent submission
    }
  
    const user = JSON.parse(localStorage.getItem("user"));
  
    if (user !== null) {
      reviews.customerName = user?.name;
      reviews.customerId = user?.userId;
      reviews.productId = id;
  
      setIsLoading(true);
  
      postData("/api/productReviews/add", reviews).then((res) => {
        setIsLoading(false);
  
        reviews.customerRating = 1;
  
        setReviews({
          review: "",
          customerRating: 1,
        });
  
        fetchDataFromApi(`/api/productReviews?productId=${id}`).then((res) => {
          setreviewsData(res);
        });
      });
    } else {
      context.setAlertBox({
        open: true,
        error: true,
        msg: "Please Login first",
      });
    }
  };
  const isActiveColor = (index) => {
    setActiveColor(index);
    setTabError(false);
  };

  const quantity = (val) => {
    setProductQuantity(val);
  };

const addtoCart = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  // Check if size and color options are provided in productData
  const hasSizeOption = productData?.size && productData.size.length > 0;
  const hasColorOption = productData?.color && productData.color.length > 0;

  // Validation for size if size options are provided
  if (hasSizeOption && activeSize === null) {
      setTabError(true);
      context.setAlertBox({
          open: true,
          error: true,
          msg: "Please select size",
      });
      return; // Stop further execution
  }

  // Validation for color if color options are provided
  if (hasColorOption && activeColor === null) {
      setTabError(true);
      context.setAlertBox({
          open: true,
          error: true,
          msg: "Please select color",
      });
      return; // Stop further execution
  }

  // Populate cartFields with product details
  // cartFields.productTitle = productData?.name;
  // cartFields.image = productData?.images[0];
  // cartFields.rating = productData?.rating;
  // cartFields.price = productData?.price;
  // cartFields.quantity = productQuantity;
  // cartFields.subTotal = parseInt(productData?.price * productQuantity);
  // cartFields.productId = productData?.id;
  // cartFields.countInStock = productData?.countInStock;
  // cartFields.countOrderNumber = productData?.countOrderNumber;
  // cartFields.isPopular = productData?.isPopular;
  // cartFields.userId = user?.userId;
  // cartFields.size = hasSizeOption ? productData?.size[activeSize] : 0; // Set size to 0 if no size option
  // cartFields.color = hasColorOption ? productData?.color[activeColor] : 0; // Set color to 0 if no color option

  cartFields.productTitle = productData?.name;

  // ✅ Parse images (JSON string to array)
  const imagesArray = productData?.images ? JSON.parse(productData.images) : [];
  cartFields.image = imagesArray.length > 0 ? imagesArray[0] : "";
  
  // ✅ Direct Values
  cartFields.rating = productData?.rating;
  cartFields.price = productData?.price;
  cartFields.quantity = productQuantity;
  cartFields.subTotal = parseInt(productData?.price * productQuantity);
  cartFields.productId = productData?.id;
  cartFields.countInStock = productData?.countInStock;
  cartFields.countOrderNumber = productData?.countOrderNumber;
  cartFields.isPopular = productData?.isPopular;
  cartFields.userId = user?.userId;
  
  // ✅ Parse size array
  const sizeArray = productData?.size ? JSON.parse(productData.size) : [];
  cartFields.size = hasSizeOption ? sizeArray[activeSize] || 0 : 0; 
  
  // ✅ Parse color array
  const colorArray = productData?.color ? JSON.parse(productData.color) : [];
  cartFields.color = hasColorOption ? colorArray[activeColor] || 0 : 0; 

  context.addToCart(cartFields);
};



// const shopnow = async () => {


//   const user = JSON.parse(localStorage.getItem("user"));

//   // Check if size and color options are provided in productData
//   const hasSizeOption = productData?.size && productData.size.length > 0;
//   const hasColorOption = productData?.color && productData.color.length > 0;

//   // Validation for size if size options are provided
//   if (hasSizeOption && activeSize === null) {
//       setTabError(true);
//       context.setAlertBox({
//           open: true,
//           error: true,
//           msg: "Please select size",
//       });
//       return; // Stop further execution
//   }

//   // Validation for color if color options are provided
//   if (hasColorOption && activeColor === null) {
//       setTabError(true);
//       context.setAlertBox({
//           open: true,
//           error: true,
//           msg: "Please select color",
//       });
//       return; // Stop further execution
//   }

//   // Create productDirectOrder object
//   const productDirectOrder = {
//       productTitle: productData?.name,
//       image: productData?.images[0],
//       rating: productData?.rating,
//       price: productData?.price,
//       quantity: productQuantity,
//       subTotal: parseInt(productData?.price * productQuantity),
//       productId: productData?.id,
//       countInStock: productData?.countInStock,
//       userId: user?.userId,
//       size: hasSizeOption ? productData?.size[activeSize] : null, // Add size if available
//       color: hasColorOption ? productData?.color[activeColor] : null, // Add color if available
//   };

//   // Navigate with productDirectOrder data if user is logged in
//   if (user) {
//       history("/ordercheckout", { state: { productDirectOrder } });
//   }else {
//     context.setAlertBox({
//       open: true,
//       error: true,
//       msg: "Please login first",
//     });
//     history("/signIn");
//   }
// };

const shopnow = async () => { 
  const user = JSON.parse(localStorage.getItem("user"));

  // Check if size and color options are provided in productData
  const hasSizeOption = productData?.size && productData.size.length > 0;
  const hasColorOption = productData?.color && productData.color.length > 0;

  // Validation for size if size options are provided
  if (hasSizeOption && activeSize === null) {
      setTabError(true);
      context.setAlertBox({
          open: true,
          error: true,
          msg: "Please select size",
      });
      return; // Stop further execution
  }

  // Validation for color if color options are provided
  if (hasColorOption && activeColor === null) {
      setTabError(true);
      context.setAlertBox({
          open: true,
          error: true,
          msg: "Please select color",
      });
      return; // Stop further execution
  }

  // Create productDirectOrder object
  // const productDirectOrder = {
  //     productTitle: productData?.name,
  //     image: productData?.images[0],
  //     rating: productData?.rating,
  //     price: productData?.price,
  //     quantity: productQuantity,
  //     subTotal: parseInt(productData?.price * productQuantity),
  //     productId: productData?.id,
  //     countInStock: productData?.countInStock,
  //     userId: user?.userId,
  //     size: hasSizeOption ? productData?.size[activeSize] : null,
  //     color: hasColorOption ? productData?.color[activeColor] : null,
  // };
//   const productDirectOrder = {
//     productTitle: productData?.name,
//     image: productData?.images ? JSON.parse(productData.images)[0] : null,  // ✅ Image array ka first element extract kiya
//     rating: productData?.rating,
//     price: productData?.price,
//     quantity: productQuantity,
//     subTotal: parseInt(productData?.price * productQuantity),
//     productId: productData?.id,
//     countInStock: productData?.countInStock,
//     userId: user?.userId,
//     size: hasSizeOption ? JSON.parse(productData?.size)[activeSize] : null,  // ✅ Size array parse kiya
//     color: hasColorOption ? JSON.parse(productData?.color)[activeColor] : null, // ✅ Color array parse kiya
// };

// const productDirectOrder = {
//     productTitle: productData?.name,
//     image: productData?.images ? JSON.parse(productData.images)[0] : null,
//     rating: productData?.rating,
//     price: parseFloat(productData?.price), // String se number mein convert karo taake match ho
//     quantity: productQuantity,
//     subTotal: parseInt(productData?.price * productQuantity),
//     productId: productData?.id,
//     countInStock: productData?.countInStock,
//     userId: user?.userId,
//     size: hasSizeOption ? JSON.parse(productData?.size)[activeSize] : null,
//     color: hasColorOption ? JSON.parse(productData?.color)[activeColor] : null,
//     countOrderNumber: productData?.countOrderNumber || 0, // Default 0, agar productData mein nahi hai
//     isPopular: productData?.isPopular || false // Default false, agar productData mein nahi hai
// };
const productDirectOrder = {
  id: null, // Yeh null rakho kyunke direct order ka abhi Cart mein ID nahi hai
  productTitle: productData?.name,
  image: productData?.images ? JSON.parse(productData.images)[0] : null,
  rating: productData?.rating,
  price: parseFloat(productData?.price),
  quantity: productQuantity,
  subTotal: parseInt(productData?.price * productQuantity),
  productId: productData?.id,
  countInStock: productData?.countInStock,
  userId: user?.userId,
  size: hasSizeOption ? JSON.parse(productData?.size)[activeSize] : null,
  color: hasColorOption ? JSON.parse(productData?.color)[activeColor] : null,
  countOrderNumber: productData?.countOrderNumber || 0,
  isPopular: productData?.isPopular || false,
  createdAt: new Date().toISOString(), // Current time for simulation
  updatedAt: new Date().toISOString(), // Current time for simulation
  Product: {
      name: productData?.name,
      price: productData?.price,
      images: productData?.images, // Stringified array as it is
      countInStock: productData?.countInStock
  }
};
  // If user is logged in, navigate with productDirectOrder data
  if (user) {
      history("/ordercheckout", { state: { productDirectOrder } });
  } else {
      // Save product data temporarily in localStorage
      localStorage.setItem("pendingOrder", JSON.stringify(productDirectOrder));

      // Show alert and redirect to login page
      context.setAlertBox({
          open: true,
          error: true,
          msg: "Please login first",
      });
      history("/signIn");
  }
};
  const selectedItem = () => { };

  const gotoReviews = () => {
    window.scrollTo({
      top: 550,
      behavior: "smooth",
    });

    setActiveTabs(2);
  };

 
  const removeItem = (id) => {
    setIsLoading(true);
    deleteData(`/api/my-list/${id}`).then((res) => {
        context.setAlertBox({
            open: true,
            error: false,
            msg: "item removed from My List!"
        })

        const user = JSON.parse(localStorage.getItem("user"));
        fetchDataFromApi(`/api/my-list?userId=${user?.userId}`).then((res) => {
            setmyListData(res);
            setIsLoading(false);
        })

    })
}




const toggleMyList = async (id) => {
    if (isLoading) return; // If a request is already in progress, don't allow further clicks

    setIsLoading(true); // Set loading to true while processing
    const user = JSON.parse(localStorage.getItem("user"));
    
    if (user !== undefined && user !== null && user !== "") {
        try {
            // Check if product is already in My List
            const res = await fetchDataFromApi(`/api/my-list?productId=${id}&userId=${user?.userId}`);
            if (res.length !== 0) {
                // If product is in My List, remove it
                await removeItem(res[0].id);
                setIsAddedToMyList(false);  // Update the heart icon to empty
            } else {
                // If product is not in My List, add it
                const data = {
                    productTitle: productData?.name,
                    image: productData?.images[0],
                    rating: productData?.rating,
                    price: productData?.price,
                    productId: id,
                    userId: user?.userId,
                    color: productData?.color[activeColor],
                    size: productData?.size[activeSize]
                };
                const response = await postData(`/api/my-list/add/`, data);
                if (response.status !== false) {
                    context.setAlertBox({
                        open: true,
                        error: false,
                        msg: "Product added to My List",
                    });
                    setIsAddedToMyList(true);  // Update heart icon to filled
                } else {
                    context.setAlertBox({
                        open: true,
                        error: true,
                        msg: response.msg,
                    });
                }
            }
        } catch (error) {
            console.log("Error occurred:", error);
            context.setAlertBox({
                open: true,
                error: true,
                msg: "Something went wrong! Please try again.",
            });
        } finally {
            setIsLoading(false); // Reset the loading state after the request is done
        }
    } else {
        context.setAlertBox({
            open: true,
            error: true,
            msg: "Please Login to continue",
        });
        setIsLoading(false); // Reset loading state if user is not logged in
    }
};


  return (
    <>
      <section className="productDetails  section">
        <div className="container">
          {productData?.length === 0 ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ minHeight: "300px" }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div className="row">
              <div className="col-md-3 pl-5 part1">
                <ProductZoom
                  images={productData?.images}
                  discount={productData?.discount}
                />
              </div>

              <div className="col-md-5 pl-3 pr-3 part2">
                <h2 className="hd text-capitalize">{productData?.name}</h2>
                <ul className="list list-inline d-flex align-items-center">
                  <li className="list-inline-item">
                    <div className="d-flex align-items-center">
                      <span className="text-light mr-2">Brands : </span>
                      <span>{productData?.brand}</span>
                    </div>
                  </li>

                  <li className="list-inline-item">
                    <div className="d-flex align-items-center">
                      <Rating
                        name="read-only"
                        style={{color:"#14274e"}}
                        value={parseInt(productData?.rating)}
                        precision={0.5}
                        readOnly
                        size="small"
                      />

                      <span
                        className="text-light cursor ml-2"
                        onClick={gotoReviews}
                      >
                        {reviewsData?.length} Review
                      </span>
                    </div>
                  </li>
                </ul>

                <div className="d-flex info mb-2">
                  <span className="oldPrice text-danger">Rs: {productData?.oldPrice}</span>
                  <span className="netPrice  ml-2">
                    Rs: {productData?.price}
                  </span>
                </div>

                {productData?.countInStock >= 1 ? (
                  <span className="badge badge-success">IN STOCK</span>
                ) : (
                  <span className="badge badge-danger">OUT OF STOCK</span>
                )}

                <p className="mt-2"> {productData?.description}</p>
{/* 
                {productData?.productRam?.length !== 0 && (
                  <div className="productSize d-flex align-items-center">
                    <span>RAM:</span>
                    <ul
                      className={`list list-inline mb-0 pl-4 ${tabError === true && "error"
                        }`}
                    >
                      {productData?.productRam?.map((item, index) => {
                        return (
                          <li className="list-inline-item">
                            <a
                              className={`tag ${activeSize === index ? "active" : ""
                                }`}
                              onClick={() => isActive(index)}
                            >
                              {item}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}

                {productData?.size?.length !== 0 && (
                  <div className="productSize d-flex align-items-center">
                    <span>Size:</span>
                    <ul
                      className={`list list-inline mb-0 pl-4 ${tabError === true && "error"
                        }`}
                    >
                      {productData?.size?.map((item, index) => {
                        return (
                          <li className="list-inline-item">
                            <a
                              className={`tag ${activeSize === index ? "active" : ""
                                }`}
                              onClick={() => isActive(index)}
                            >
                              {item}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}

                {productData?.color?.length !== 0 && (
                  <div className="productSize d-flex align-items-center">
                    <span>Color:</span>
                    <ul
                      className={`list list-inline mb-0 pl-4 ${tabError === true && "error"
                        }`}
                    >
                      {productData?.color?.map((item, index) => {
                        return (
                          <li className="list-inline-item">
                            <a
                              className={`tag ${activeColor === index ? "active" : ""
                                }`}
                              onClick={() => isActiveColor(index)}
                            >
                              {item}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
                {productData?.productWeight?.length !== 0 && (
                  <div className="productSize d-flex align-items-center">
                    <span>Weight:</span>
                    <ul
                      className={`list list-inline mb-0 pl-4 ${tabError === true && "error"
                        }`}
                    >
                      {productData?.productWeight?.map((item, index) => {
                        return (
                          <li className="list-inline-item">
                            <a
                              className={`tag ${activeSize === index ? "active" : ""
                                }`}
                              onClick={() => isActive(index)}
                            >
                              {item}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )} */}

{productData?.productRam && JSON.parse(productData.productRam).length !== 0 && (
  <div className="productSize d-flex align-items-center">
    <span>RAM:</span>
    <ul className={`list list-inline mb-0 pl-4 ${tabError && "error"}`}>
      {JSON.parse(productData.productRam).map((item, index) => (
        <li className="list-inline-item" key={index}>
          <a
            className={`tag ${activeSize === index ? "active" : ""}`}
            onClick={() => isActive(index)}
          >
            {item}
          </a>
        </li>
      ))}
    </ul>
  </div>
)}

{productData?.size && JSON.parse(productData.size).length !== 0 && (
  <div className="productSize d-flex align-items-center">
    <span>Size:</span>
    <ul className={`list list-inline mb-0 pl-4 ${tabError && "error"}`}>
      {JSON.parse(productData.size).map((item, index) => (
        <li className="list-inline-item" key={index}>
          <a
            className={`tag ${activeSize === index ? "active" : ""}`}
            onClick={() => isActive(index)}
          >
            {item}
          </a>
        </li>
      ))}
    </ul>
  </div>
)}

{productData?.color && JSON.parse(productData.color).length !== 0 && (
  <div className="productSize d-flex align-items-center">
    <span>Color:</span>
    <ul className={`list list-inline mb-0 pl-4 ${tabError && "error"}`}>
      {JSON.parse(productData.color).map((item, index) => (
        <li className="list-inline-item" key={index}>
          <a
            className={`tag ${activeColor === index ? "active" : ""}`}
            onClick={() => isActiveColor(index)}
          >
            {item}
          </a>
        </li>
      ))}
    </ul>
  </div>
)}

{productData?.productWeight && JSON.parse(productData.productWeight).length !== 0 && (
  <div className="productSize d-flex align-items-center">
    <span>Weight:</span>
    <ul className={`list list-inline mb-0 pl-4 ${tabError && "error"}`}>
      {JSON.parse(productData.productWeight).map((item, index) => (
        <li className="list-inline-item" key={index}>
          <a
            className={`tag ${activeSize === index ? "active" : ""}`}
            onClick={() => isActive(index)}
          >
            {item}
          </a>
        </li>
      ))}
    </ul>
  </div>
)}


                <div className="d-flex align-items-center mt-3 actions_">
                  <QuantityBox
                    quantity={quantity}
                    item={productData}
                    selectedItem={selectedItem}

                  />

                  <div className="d-flex align-items-center btnActions">
                    <Button
                      className=" btn-lg btn-big btn-round colorbtnmy"
                      onClick={() => addtoCart()}
                    >
                      <BsCartFill /> &nbsp;
                      {context.addingInCart === true
                        ? "adding..."
                        : " Add to cart"}
                    </Button>

      




<Tooltip
    title={`${isAddedToMyList === true ? "Added to Wishlist" : "Add to Wishlist"}`}
    placement="top"
>
    <Button
        className={`btn-blue btn-lg btn-big btn-circle ml-4`}
        onClick={() => toggleMyList(id)}
        disabled={isLoading} // Disable button while loading
    >
        {isAddedToMyList === true ? (
            <FaHeart className="text-danger" />
        ) : (
            <FaRegHeart />
        )}
    </Button>
</Tooltip>

                  </div>

                </div>

               

              </div>
              <div className="col-md-4">
                                    <div className="card border p-3 cartDetails">
                                        <h4> TOTAL PAYMENT</h4>

                                        <div className="d-flex align-items-center mb-3">
                                            <span>Subtotal</span>
                                            <span className="ml-auto text-red font-weight-bold">
                                         <b>

{
        (
        parseInt(productData?.price * productQuantity)
             
        )
       
    }
   </b>
                                            </span>
                                        </div>

                                        <div className="d-flex align-items-center mb-3">
    <span>Shipping</span>
    <span className="ml-auto"><b> FREE </b></span> 
</div>


                                        <div className="d-flex align-items-center mb-3">
                                            <span>Estimate for</span>
                                            <span className="ml-auto"><b>KARACHI</b></span>
                                        </div>


                                        <div className="d-flex align-items-center">
    <span>Total</span>
    <span className="ml-auto text-red font-weight-bold">
   <b>
    {
        (
          parseInt(productData?.price * productQuantity) // + 199
             
        )
       
    }
    </b>
    </span>
</div>



                                        <br />
                                            <Button onClick={() => shopnow()} className='  btnspecialborderspersonal'>
                                              <span></span>
                                              <span></span>
                                              <span></span>
                                              <span></span>
                                              <IoBagCheckOutline /> &nbsp;   ORDER NOW</Button>

                                    </div>
                                </div>

            </div>
          )}
        

     


          <br />

          <div className="card mt-5 p-5 detailsPageTabs">
            <div className="customTabs">
              <ul className="list list-inline">
                <li className="list-inline-item">
                  <Button
                    className={`${activeTabs === 0 && "active"}`}
                    onClick={() => {
                      setActiveTabs(0);
                    }}
                  >
                    Description
                  </Button>
                </li>
                <li className="list-inline-item">
                  <Button
                    className={`${activeTabs === 1 && "active"}`}
                    onClick={() => {
                      setActiveTabs(1);
                    }}
                  >
                    Additional info
                  </Button>
                </li>
                <li className="list-inline-item">
                  <Button
                    className={`${activeTabs === 2 && "active"}`}
                    onClick={() => {
                      setActiveTabs(2);
                    }}
                  >
                    Reviews ({reviewsData?.length})
                  </Button>
                </li>
              </ul>

              <br />

              {activeTabs === 0 && (
                <div className="tabContent">{productData?.description}</div>
              )}

              {activeTabs === 1 && (
                <div className="tabContent">
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <tbody>
                        <tr className="stand-up">
                          <th>Color</th>
                          <td>
                            <p>

                           {productData?.color?.length !== 0 && (
                  <div className="productSize d-flex align-items-center">
                  
                    <ul
                      className={`list list-inline mb-0 pl-4 ${tabError === true && "error"
                        }`}
                    >
                  {JSON.parse(productData?.color || "[]")?.map((item, index) => {
    return (
        <li className="list-inline-item" key={index}>
            <a>{item}</a>
        </li>
    );
})}
                    </ul>
                  </div>
                )}


                              
                              </p>
                          </td>
                        </tr>
                        <tr className="folded-wo-wheels">
                          <th>size</th>
                          <td>
                            <p>
                            {productData?.size?.length !== 0 && (
                  <div className="productSize d-flex align-items-center">
                  
                    <ul
                      className={`list list-inline mb-0 pl-4 ${tabError === true && "error"
                        }`}
                    >
               {JSON.parse(productData?.size || "[]")?.map((item, index) => {
    return (
        <li className="list-inline-item" key={index}>
            <a>{item}</a>
        </li>
    );
})}
                    </ul>
                  </div>
                )}
                              </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )}

             {activeTabs === 2 && (
                <div className="tabContent">
                  <div className="row">
                    <div className="col-md-8">
                      <h3>Customer questions & answers</h3>
                      <br />

                
                      <br className="res-hide" />

                      <form className="reviewForm" onSubmit={addReview}>
                        <h4>Add a review</h4>
                        <div className="form-group">
                          <textarea
                            className="form-control shadow"
                            placeholder="Write a Review"
                            name="review"
                            value={reviews.review}
                            onChange={onChangeInput}
                          ></textarea>
                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <Rating
                                name="rating"
                                style={{color:"#14274e"}}
                                value={rating}
                                precision={0.5}
                                onChange={changeRating}
                              />
                            </div>
                          </div>
                        </div>

                        <br />
                        <div className="form-group">
                          <Button
                            type="submit"
                            className=" btn-lg btn-big btn-round colorbtnmy"
                          >
                            {isLoading === true ? (
                              <CircularProgress
                                color="inherit"
                                className="loader"
                              />
                            ) : (
                              "Submit Review"
                            )}
                          </Button>
                        </div>
                      </form>

                    <br/>
                    {reviewsData?.length !== 0 &&
                        reviewsData
                          ?.slice(0)
                          ?.reverse()
                          ?.map((item, index) => {
                            return (
                              <div
                                className="reviewBox mb-4 border-bottom"
                                key={index}
                              >
                                <div className="info">
                                  <div className="d-flex align-items-center w-100">
                                    <h5>{item?.customerName}</h5>

                                    <div className="ml-auto">
                                      <Rating
                                        name="half-rating-read"
                                         style={{color:"#14274e"}}
                                        value= {parseInt(item?.rating)}
                                        readOnly
                                        size="small"
                                      />
                                    </div>
                                  </div>

                                  <h6 className="text-light">
                                    {item?.createdAt?.split('T')[0]}
                                  </h6>

                                  <p>{item?.comment} </p>
                                </div>
                              </div>
                            );
                          })}

                    </div>
                  </div>
                </div>
              )} 


            </div>
          </div>

          <br />

          {relatedProductData?.length !== 0 && (
            <RelatedProducts
              title="RELATED PRODUCTS"
              data={relatedProductData}
            />
          )}
        </div>
      </section>
    </>
  );
};

export default ProductDetails;
