import Dialog from "@mui/material/Dialog";
import { MdClose } from "react-icons/md";
import Button from "@mui/material/Button";
import Rating from "@mui/material/Rating";
import { useContext, useEffect, useState } from "react";
import QuantityBox from "../QuantityBox";
import { IoIosHeartEmpty, IoMdHeartEmpty } from "react-icons/io";
import { MdOutlineCompareArrows } from "react-icons/md";
import { MyContext } from "../../App";
import ProductZoom from "../ProductZoom";
import { IoCartSharp } from "react-icons/io5";
import { editData, fetchDataFromApi, postData } from "../../utils/api";
import { FaHeart } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BsCartFill } from "react-icons/bs";
import { deleteData } from "../../utils/api";

const ProductModal = (props) => {
  const [productQuantity, setProductQuantity] = useState();
  const [chengeQuantity, setchengeQuantity] = useState(0);
  let [cartFields, setCartFields] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [activeSize, setActiveSize] = useState(null);
  const [activeColor, setActiveColor] = useState(null);
  const history = useNavigate();
  const [tabError, setTabError] = useState(false);
  const [isAddedToMyList, setSsAddedToMyList] = useState(false);
  const [productData, setProductData] = useState([]);
  const [myListData, setmyListData] = useState([]);

  const context = useContext(MyContext);

  useEffect(() => {
    if (
      props?.data?.productRam.length === 0 &&
      props?.data?.productWeight.length === 0 &&
      props?.data?.size.length === 0
    ) {
      setActiveSize(1);
    }

    const user = JSON.parse(localStorage.getItem("user"));

    fetchDataFromApi(
      `/api/my-list?productId=${props?.data?.id}&userId=${user?.userId}`
    ).then((res) => {
      if (res.length !== 0) {
        setSsAddedToMyList(true);
      }
    });
  }, []);


  const quantity = (val) => {
    setProductQuantity(val);
    setchengeQuantity(val);
  };

  const isActive = (index) => {
    setActiveSize(index);
    setTabError(false);
  };
  const isActiveColor = (index) => {
    setActiveColor(index);
    setTabError(false);
  };
  const shopnow = async () => {
    const hasSizeOption =JSON.parse( props?.data?.size) && JSON.parse(props?.data.size).length > 0;
    const hasColorOption =JSON.parse( props?.data?.color) && JSON.parse(props?.data.color).length > 0;
    // const hasColorOption = props?.data?.color && productData.color.length > 0;
  
    // Validation for size if size options are provided
    if (hasSizeOption && activeSize === null) {
        setTabError(true);
        context.setAlertBox({
            open: true,
            error: true,
            msg: "Please select size",
        });
        return; // Stop further execution
    }
  
    // Validation for color if color options are provided
    if (hasColorOption && activeColor === null) {
        setTabError(true);
        context.setAlertBox({
            open: true,
            error: true,
            msg: "Please select color",
        });
        return; // Stop further execution
    }
  
     

      const user = JSON.parse(localStorage.getItem("user"));
      const productDirectOrder = {
        productTitle: props?.data?.name || "", // Default value
        image: Array.isArray(props?.data?.images)
          ? props?.data?.images[0]
          : JSON.parse(props?.data?.images || "[]")[0] || "", // Ensuring correct format
        rating: props?.data?.rating || 0,
        price: parseFloat(props?.data?.price) || 0,
        quantity: productQuantity || 1,
        subTotal: parseFloat(props?.data?.price) * (productQuantity || 1),
        productId: props?.data?.id || null,
        countInStock: props?.data?.countInStock || 0,
        userId: user?.userId || null,
        size: Array.isArray(props?.data?.size) ? props?.data?.size[activeSize] : "",
        color: Array.isArray(props?.data?.color) ? props?.data?.color[activeColor] : "",
      };
      if (user) {
        context.setisOpenProductModal(false);
        history("/ordercheckout", { state: { productDirectOrder } });
      } else {
        context.setAlertBox({
          open: true,
          error: true,
          msg: "Please login first",
        });
      }
    
  };


  return (
    <>
      <Dialog
        open={context.isOpenProductModal}
        className="productModal"
        onClose={() => context.setisOpenProductModal(false)}
      >
        <Button
          className="close_"
          onClick={() => context.setisOpenProductModal(false)}
        >
          <MdClose />
        </Button>
        <h4 class="mb-1 font-weight-bold pr-5">{props?.data?.name}</h4>
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center mr-4">
            <span>Brands:</span>
            <span className="ml-2">
              <b>{props?.data?.brand}</b>{" "}
            </span>
          </div>

          <Rating
            name="read-only"
            value={parseInt(props?.data?.rating)}
            size="small"
            precision={0.5}
            readOnly
          />
        </div>

        <hr />

        <div className="row mt-2 productDetaileModal">
          <div className="col-md-5">
            <ProductZoom
              images={props?.data?.images}
              discount={props?.data?.discount}
            />
          </div>

          <div className="col-md-7">
            <div className="d-flex info align-items-center mb-3">
              <span className="oldPrice lg mr-2">
                Rs: {props?.data?.oldPrice}
              </span>
              <span className="netPrice text-danger lg">
                Rs: {props?.data?.price}
              </span>
            </div>

            <span className="badge bg-success">IN STOCK</span>

            <p className="mt-3"> {props?.data?.description}</p>

         
{props?.data?.productRam && JSON.parse(props.data.productRam).length > 0 && (
  <div className="productSize d-flex align-items-center">
    <span>RAM:</span>
    <ul className={`list list-inline mb-0 pl-4 ${tabError ? "error" : ""}`}>
      {JSON.parse(props.data.productRam).map((item, index) => (
        <li className="list-inline-item" key={index}>
          <a
            className={`tag ${activeSize === index ? "active" : ""}`}
            onClick={() => isActive(index)}
          >
            {item}
          </a>
        </li>
      ))}
    </ul>
  </div>
)}
            {props?.data?.size?.length !== 0 && (
              <div className="productSize d-flex align-items-center">
                <span>Size:</span>
                <ul
                  className={`list list-inline mb-0 pl-4 ${
                    tabError === true && "error"
                  }`}
                >
           {props?.data?.size &&
  JSON.parse(props.data.size).map((item, index) => (
    <li className="list-inline-item" key={index}>
      <a
        className={`tag ${activeSize === index ? "active" : ""}`}
        onClick={() => isActive(index)}
      >
        {item}
      </a>
    </li>
  ))}
                </ul>
              </div>
            )}

{props?.data?.color && JSON.parse(props.data.color).length !== 0 && (
  <div className="productSize d-flex align-items-center">
    <span>Color:</span>
    <ul
      className={`list list-inline mb-0 pl-4 ${tabError ? "error" : ""}`}
    >
      {JSON.parse(props.data.color).map((item, index) => (
        <li className="list-inline-item" key={index}>
          <a
            className={`tag ${activeColor === index ? "active" : ""}`}
            onClick={() => isActiveColor(index)}
          >
            {item}
          </a>
        </li>
      ))}
    </ul>
  </div>
)}

{props?.data?.productWeight && JSON.parse(props.data.productWeight).length > 0 && (
  <div className="productSize d-flex align-items-center">
    <span>Weight:</span>
    <ul className={`list list-inline mb-0 pl-4 ${tabError ? "error" : ""}`}>
      {JSON.parse(props.data.productWeight).map((item, index) => (
        <li className="list-inline-item" key={index}>
          <a
            className={`tag ${activeSize === index ? "active" : ""}`}
            onClick={() => isActive(index)}
          >
            {item}
          </a>
        </li>
      ))}
    </ul>
  </div>
)}
            <div className="d-flex align-items actions_ mt-3">
              <QuantityBox quantity={quantity} item={props?.data} />

            
            </div>

            <div className="d-flex align-items-center mt-4 actions">
         
         
              <Button
                className=" btn-lg btn-big btn-round colorbtnmy ml-3"
                onClick={() => shopnow()}
              >
                ORDER NOW
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default ProductModal;
