import React, { useEffect, useState } from "react";
import { fetchDataFromApi } from "../../utils/api";
import Pagination from "@mui/material/Pagination";
import Dialog from "@mui/material/Dialog";
import { MdClose } from "react-icons/md";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [products, setproducts] = useState([]);
  const [page, setPage] = useState(1);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isLogin, setIsLogin] = useState(false);

  const history = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);

    const token = localStorage.getItem("token");
    if (token !== "" && token !== undefined && token !== null) {
      setIsLogin(true);
    } else {
      history("/signIn");
    }

    const user = JSON.parse(localStorage.getItem("user"));
    fetchDataFromApi(`/api/orders/user/${user?.userId}`).then((res) => {
      setOrders(res);
    });
  }, []);

  const viewOrderDetails = (id) => {
    history(`/order-info/${id}`);
  };

  const showProducts = (id) => {
    fetchDataFromApi(`/api/orders/${id}`).then((res) => {
      setIsOpenModal(true);
      setproducts([res.products]);
    });
  };
  const safeParseJSON = (data) => {
    try {
      let parsed = JSON.parse(data);
      // Agar parsed data bhi ek string hai, toh ek aur baar parse karo
      if (typeof parsed === "string") {
        parsed = JSON.parse(parsed);
      }
      return parsed;
    } catch (error) {
      console.error("JSON Parse Error:", error);
      return null; // Error ki surat mein null return kar do
    }
  };

  return (
    <>
      <section className="section OrderPage">
        <div className="container">
          <h2 className="hd">Orders</h2>

          <div className="table-responsive orderTable w-100">
            <table className="table table-striped bordertable">
              <thead className="thead-light">
                <tr>
                  <th>Order Id</th>
                  <th>Products</th>
                  <th>Name</th>
                  <th>Phone Number</th>
                  <th>Address</th>
                  <th>Pincode</th>
                  <th>Total Amount</th>
                  <th>Email</th>
                  <th>User Id</th>
                  <th>Order Status</th>
                  <th>Date</th>
                </tr>
              </thead>

              <tbody>
                {orders?.length !== 0 &&
                  orders?.map((order, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <td>
                            <span className="text-blue fonmt-weight-bold">
                              {order?.id}
                            </span>
                          </td>

                          <td>
                            <Button
                              className=" colorbtnmy font-weight-bold cursor"
                              onClick={() => showProducts(order?.id)}
                            >
                              {" "}
                              Click here to view
                            </Button>
                          </td>
                          <td>{order?.name}</td>
                          <td>{order?.phone}</td>
                          <td>{order?.address}</td>
                          <td>{order?.pincode}</td>
                          <td>{parseInt(order?.amount)}</td>
                          <td>{order?.email}</td>
                          <td>{order?.userid}</td>
                          <td>
                            {order?.status === "canceled" ? (
                              <span className="badge badge-danger">
                                {order?.status}
                              </span>
                            ) : (
                              <span className="badge badge-success">
                                {order?.status}
                              </span>
                            )}
                          </td>
                          {/* <td>{order?.date?.split("T")[0]}</td> */}
                          <td>
                            {order?.date?.split("T")[0]}

                            <span
                              className="seemorebtn"
                              onClick={() => viewOrderDetails(order?.id)}
                            >
                              View Details
                            </span>
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </section>

      <Dialog open={isOpenModal} className="productModal">
        <Button className="close_" onClick={() => setIsOpenModal(false)}>
          <MdClose />
        </Button>
        <h4 className="mb-1 font-weight-bold pr-5 mb-4">Products</h4>

        <div className="table-responsive orderTable">
          <table className="table table-striped table-bordered">
            <thead className="thead-light">
              <tr>
                <th>Product Title</th>
                <th>Image</th>
                <th>Color</th>
                <th>Size</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Total</th>
              </tr>
            </thead>

            <tbody>
              {products?.length !== 0 &&
                (() => {
                  let parsedProducts = safeParseJSON(products);

                  if (!Array.isArray(parsedProducts)) {
                    return (
                      <tr>
                        <td colSpan="7">Data parse karne mein error</td>
                      </tr>
                    );
                  }

                  return parsedProducts.map((parsedItem, index) => {
                    const image =
                      parsedItem?.image && typeof parsedItem?.image === "string"
                        ? parsedItem.image.startsWith("[")
                          ? JSON.parse(parsedItem.image)[0]
                          : parsedItem.image
                        : "https://via.placeholder.com/50"; // Default image agar undefined ho

                    return (
                      <tr key={index}>
                        <td style={{ whiteSpace: "inherit" }}>
                          <span>
                            {parsedItem?.productTitle?.substr(0, 30) + "..."}
                          </span>
                        </td>
                        <td>
                          <div className="img smlimg">
                            <img src={image} alt="Product" />
                          </div>
                        </td>
                        <td>{parsedItem?.color || "N/A"}</td>
                        <td>{parsedItem?.size || "N/A"}</td>
                        <td>{parsedItem?.quantity || 0}</td>
                        <td>{parsedItem?.price || 0}</td>
                        <td>{parsedItem?.subTotal || 0}</td>
                      </tr>
                    );
                  });
                })()}
            </tbody>
          </table>
        </div>
      </Dialog>
    </>
  );
};

export default Orders;
