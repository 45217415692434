
  import React from "react";
  import { Swiper, SwiperSlide } from "swiper/react";
  import { Navigation, Pagination , Autoplay } from "swiper/modules";
  import "swiper/css";
  import "swiper/css/navigation";
  import "swiper/css/pagination";
  import "./Banner.css";
  import "../../responsive.css"
  const CheckBanner = (props) => {

    return (
      <div className="banner-container">
        <Swiper
          slidesPerView={1.3} // Center banner + Side Previews
          spaceBetween={0}
          loop={true}
          speed={1000}
          pagination={{ clickable: true }}
          modules={[Navigation, Pagination , Autoplay]}
          centeredSlides={true} // Center main hoga
          autoplay={{
            delay: 3500,
            disableOnInteraction: false,
        }}
          breakpoints={{
            768: {
              slidesPerView: 1.5, // Tablet pe side preview zyada dikhayega
            },
            1024: {
              slidesPerView: 1.1, // Desktop pe aur zyada preview dikhayega
            },
          }}
          className="swiper-container"
        >
         
            <SwiperSlide  className="swiper-slide">
              <img src="https://res.cloudinary.com/dm02wcpvm/image/upload/v1742850437/1742850435136_Cashback_Service_Colorful_Facebook_Cover_Photo.png" alt={"Banner" } className="banner-img" />
            </SwiperSlide>

{props.data.map((item, index) => (
            <SwiperSlide key={index} className="swiper-slide">
              <img src={JSON.parse(item.images)} alt={`Banner ${index + 1}`} className="banner-img" />
            </SwiperSlide>
          ))} 
        </Swiper>
      </div>
    );
  };
  
  export default CheckBanner;
  

