import React, { useContext, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { IoBagCheckOutline } from "react-icons/io5";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import emprtCart from '../../assets/images/emptyCart.png';
import thanks from '../../assets/images/thanku.png';

import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { MyContext } from "../../App";
import { fetchDataFromApi, postData,editData, deleteData } from "../../utils/api";

import { Link, useNavigate } from "react-router-dom";
import { FaHome } from "react-icons/fa";
const Checkout = () => {










const [formFieldsF, setFormFieldsF] = useState({
    name: '',
    subCat: '',
    subCatName:'',
    description: '',
    brand: '',
    price: null,
    oldPrice: null,
    catName: '',
    catId: '',
    subCatId: '',
    category: '',
    countInStock: null,
    rating: 0,
    isFeatured: null,
    isNew: null,
    isPopular: null,
    countOrderNumber: 0,
    discount: 0,
    productRam: [],
    size: [],
    color:[],
    productWeight: [],
    location:""
})

  const [formFieldsP, setFormFieldsP] = useState({
    name: '',
    subCat: '',
    subCatName:'',
    description: '',
    brand: '',
    price: null,
    oldPrice: null,
    catName: '',
    catId: '',
    subCatId: '',
    category: '',
    countInStock: null,
    rating: 0,
    isFeatured: null,
    isNew: null,
    isPopular: null,
    countOrderNumber: 0,
    discount: 0,
    productRam: [],
    size: [],
    color:[],
    productWeight: [],
    location:""
})
















  const [province, setprovince] = useState('');
  const [selectedItems, setSelectedItems] = useState([]); // Fo

  const [openDialog, setOpenDialog] = useState(false); // Manage the dialog state

  const handleDialogClose = () => {
    setOpenDialog(false);
    history("/orders"); // Navigate to orders page when dialog is closed
  };


  const handleChange = (event) => {
    setprovince(event.target.value);
  };


  const [formFields, setFormFields] = useState({
    name: "",
    country: "",
    streetAddressLine1: "",
    streetAddressLine2: "",
    city: "",
    state: "",
    zipCode: "",
    phone: "",
    email: "",
  });


  const [cartData, setCartData] = useState([]);
  const [totalAmount, setTotalAmount] = useState();


  useEffect(() => {
    const fetchData = async () => {
    const user = JSON.parse(localStorage.getItem("user"));

    await  fetchDataFromApi(`/api/cart/user/${user?.userId}`).then((res) => {
        setCartData(res);
  
        setTotalAmount(
          res.length !== 0 &&
          res
            .map((item) => parseInt(item.price) * item.quantity)
            .reduce((total, value) => total + value, 0
            )
        );
      });
    };

    fetchData();
  }, []);

  useEffect(() => {
      window.scrollTo(0, 0);
  
    
      const user = JSON.parse(localStorage.getItem("user"));
  
      fetchDataFromApi(`/api/user/${user?.userId}`).then((res) => {
        // setUserData(res);
        // setPreviews(res.images);
  
        setFormFields({
          name: res.name,
          email: res.email,
          phone: res.phone,
          country : res.country,
          streetAddressLine1 : res.streetAddressLine1,
         streetAddressLine2 : res.streetAddressLine2 ,
          city : res.city ,
        state : res.state ,
        zipCode : res.zipCode ,
        });
      });
    }, []);
  


  useEffect(() => {
    window.scrollTo(0, 0);
    const user = JSON.parse(localStorage.getItem("user"));
    fetchDataFromApi(`/api/cart/user/${user?.userId}`).then((res) => {
      setCartData(res);

      setTotalAmount(
        res.length !== 0 &&
        res
          .map((item) => parseInt(item.price) * item.quantity)
          .reduce((total, value) => total + value, 0
          )
      );
    });
  }, []);

  const onChangeInput = (e) => {
    setFormFields(() => ({
      ...formFields,
      [e.target.name]: e.target.value,
    }));
  };

  const context = useContext(MyContext);
  const history = useNavigate();
const checkout = async (e) => {
  e.preventDefault();

  const user = JSON.parse(localStorage.getItem("user"));
  const selectedCartItems = JSON.parse(localStorage.getItem("selectedItems") || "[]");

  if (!selectedCartItems.length) {
    context.setAlertBox({
      open: true,
      error: true,
      msg: "No product in cart",
    });
    return;
  }

  // Validation checks
  if (formFields.name === "" || formFields.country === "" || formFields.streetAddressLine1 === "" || 
      formFields.city === "" || formFields.zipCode === "" || formFields.phone === "" || 
      formFields.state === "" || formFields.email === "") {
    context.setAlertBox({
      open: true,
      error: true,
      msg: "Please fill all required fields",
    });
    return false;
  }

  // Order details
  const addressInfo = {
    name: formFields.name,
    phone: formFields.phone,
    address: formFields.streetAddressLine1 + formFields.streetAddressLine2,
    pincode: formFields.zipCode,
    date: new Date()
  };
  const payLoad = {
    name: addressInfo.name,
    phone: formFields.phone,
    address: addressInfo.address,
    pincode: addressInfo.pincode,
    amount: parseInt(selectedTotal),
    email: user.email,
    userid: user.userId, // "userid" ki jagah "userId" use karo, consistency ke liye
    products: JSON.stringify(selectedCartItems), // Backend mein string expect karta hai
    date: addressInfo.date,
  };

  // Post order
  postData(`/api/orders/create`, payLoad).then(async (res) => {
    try {
      // Fetch cart items for the user
      const cartItems = await fetchDataFromApi(`/api/cart/user/${user?.userId}`);

      if (cartItems?.length !== 0) {
        const itemsToDelete = cartItems.filter(cartItem =>
          selectedCartItems.some(selectedItem => selectedItem.id === cartItem.id)
        );

        if (itemsToDelete.length === 0) {
        } else {
          // Delete all items concurrently
          await Promise.all(
            itemsToDelete.map(item =>
              deleteData(`/api/cart/${item?.id}`).then(() => {
              }).catch(err => {
                console.error(`Failed to delete item with ID: ${item?.id}`, err);
              })
            )
          );

          // Update product countOrderNumber and isPopular
          await Promise.all(
            itemsToDelete.map(async (item) => {
              const product = await fetchDataFromApi(`/api/products/${item?.productId}`);

              const updatedOrderNumber = {
                name: product?.name,
                description: product?.description,
                brand: product?.brand,
                price: product?.price,
                oldPrice: product?.oldPrice,
                catName: product?.catName,
                category: product?.category,
                catId: product?.catId,
                subCat: product?.subCat,
                countInStock: product?.countInStock,
                rating: product?.rating,
                isFeatured: product?.isFeatured,
                isNew: product?.isNew,
                isPopular: product?.countOrderNumber + 1 >= 5 ? true : product?.isPopular,
                countOrderNumber: product?.countOrderNumber + 1,
                discount: product?.discount,
                productRam: product?.productRam,
                size: product?.size,
                color: product?.color,
                productWeight: product?.productWeight,
                location: product?.location
              };

              await editData(`/api/products/${product?.id}`, updatedOrderNumber);
            })
          );
        }

        // Refresh cart data after deletions and updates
        setTimeout(() => {
          context.getCartData();
          context.setAlertBox({
            open: true,
            msg: "Order placed and cart updated successfully!",
            error: false
          });
          setOpenDialog(true); // Open Thank You dialog
        }, 1000);
      } else {
        setOpenDialog(true); // Still open dialog if no items to delete
      }
    } catch (error) {
      console.error("Error during checkout process:", error);
      context.setAlertBox({
        open: true,
        error: true,
        msg: "Something went wrong during checkout",
      });
    }
  }).catch(error => {
    console.error("Order creation failed:", error);
    context.setAlertBox({
      open: true,
      error: true,
      msg: "Failed to create order",
    });
  });
};



const selectedCartItems = JSON.parse(localStorage.getItem("selectedItems") || "[]");

const selectedSubtotal = selectedCartItems
  .map(item => parseInt(item.price) * item.quantity)
  .reduce((total, value) => total + value, 0);

const selectedTotal = selectedSubtotal;

  return (

    <div>
    { cartData?.length !== 0 ? (
      <section className="section CheckoutPage">
      <div className="container">
      <form className="checkoutForm" onSubmit={checkout}>
        <div className="row">
        <div className="col-md-8">
                 <h2 className="hd">BILLING DETAILS</h2>
     
                 <div className="row mt-3">
                   <div className="col-md-6">
                     <div className="form-group">
                       <TextField
                         label="Full Name *"
                         variant="outlined"
                         className="w-100"
                         size="small"
                         name="name"
                         onChange={onChangeInput}
                         value={formFields.name}
                       />
                     </div>
                   </div>
     
                   <div className="col-md-6">
                     <div className="form-group">
                       <TextField
                         label="Country *"
                         variant="outlined"
                         className="w-100"
                         size="small"
                         name="country"
                         value={formFields.country}
                         onChange={onChangeInput}
                       />
                     </div>
                   </div>
                 </div>
     
                 <h6>Street address *</h6>
     
                 <div className="row">
                   <div className="col-md-12">
                     <div className="form-group">
                       <TextField
                         label="House number and street name"
                         variant="outlined"
                         className="w-100"
                         size="small"
                         name="streetAddressLine1"
                         value={formFields.streetAddressLine1}
     
                         onChange={onChangeInput}
                       />
                     </div>
     
                     <div className="form-group">
                       <TextField
                         label="Apartment, suite, unit, etc. (optional)"
                         variant="outlined"
                         className="w-100"
                         size="small"
                         name="streetAddressLine2"
                         value={formFields.streetAddressLine2}
                         
                         onChange={onChangeInput}
                       />
                     </div>
                   </div>
                 </div>
     
                 <h6>Town / City *</h6>
     
                 <div className="row">
                   <div className="col-md-12">
                     <div className="form-group">
                       <TextField
                         label="City"
                         variant="outlined"
                         className="w-100"
                         size="small"
                         name="city"
                         value={formFields.city}
     
                         onChange={onChangeInput}
                       />
                     </div>
                   </div>
                 </div>
     
                 <h6>Provinces</h6>
     
                 <div className="row">
                   <div className="col-md-12">
                     <div className="form-group">
     
                       <TextField
                         label="State"
                         variant="outlined"
                         className="w-100"
                         size="small"
                         name="state"
                         value={formFields.state}
                         onChange={onChangeInput}
                       />
     
                     
                     </div>
                   </div>
                 </div>
     
                 <h6>Postcode / ZIP *</h6>
     
                 <div className="row">
                   <div className="col-md-12">
                     <div className="form-group">
                       <TextField
                         label="ZIP Code"
                         variant="outlined"
                         className="w-100"
                         size="small"
                         name="zipCode"
                         value={formFields.zipCode}
     
                         onChange={onChangeInput}
                       />
                     </div>
                   </div>
                 </div>
     
                 <div className="row">
                   <div className="col-md-6">
                     <div className="form-group">
                       <TextField
                         label="Phone Number"
                         variant="outlined"
                         className="w-100"
                         size="small"
                         name="phone"
                         value={formFields.phone}
     
                         onChange={onChangeInput}
                       />
                     </div>
                   </div>
     
                   <div className="col-md-6">
                     <div className="form-group">
                       <TextField
                         label="Email Address"
                         variant="outlined"
                         className="w-100"
                         size="small"
                         name="email"
                         disabled
                         value={formFields.email}
     
                         onChange={onChangeInput}
                       />
                     </div>
                   </div>
                 </div>
               </div>



          <div className="col-md-4">
            <div className="card border p-3 cartDetails">
           
            <h4>YOUR CART TOTALS</h4> 
{/*             
              <div className="d-flex align-items-center mb-3">
                                            <span>Total Items</span>
                                            <span className="ml-auto"><b>{context.cartData?.length>0 ? context.cartData?.length : 0}</b></span>
                                        </div> */}
                                         <div className="d-flex align-items-center mb-3">
                                            <span>Total Selected Items</span>
                                            <span className="ml-auto">
                                                <b>{selectedCartItems.length}</b>
                                            </span>
                                        </div>

              {/* <div className="d-flex align-items-center mb-3">
                <span>Subtotal</span>
                <span className="ml-auto text-red font-weight-bold">
                  <b>
                {
      (cartData?.length !== 0 
          ? cartData.map(item => parseInt(item.price) * item.quantity)
              .reduce((total, value) => total + value, 0) 
          : 0
      )?.toLocaleString('en-US', { style: 'currency', currency: 'PKR' })
  }
  </b>
                </span>
              </div> */}
              <div className="d-flex align-items-center mb-3">
                                            <span>Subtotal</span>
                                            <span className="ml-auto text-red font-weight-bold">
                                                <b>
                                                    {selectedSubtotal.toLocaleString('en-US', {
                                                        style: 'currency',
                                                        currency: 'PKR',
                                                    })}
                                                </b>
                                            </span>
                                        </div>

              <div className="d-flex align-items-center mb-3">
                <span>Shipping</span>
                <span className="ml-auto"><b> FREE </b></span>
              </div>

              <div className="d-flex align-items-center mb-3">
                <span>Estimate for</span>
                <span className="ml-auto"><b>KARACHI</b></span>
              </div>

              {/* <div className="d-flex align-items-center">
                <span>Total</span>
                <span className="ml-auto text-red font-weight-bold">
                <b>
                {
    (cartData?.length !== 0 
        ? cartData.map(item => parseInt(item.price) * item.quantity)
            .reduce((total, value) => total + value, 0) //+ 199 // shipping charges ko reduce ke baad ek martaba add karna
        : 199
    )?.toLocaleString('en-US', { style: 'currency', currency: 'PKR' })
}
</b>
                </span>
              </div> */}

<div className="d-flex align-items-center">
                                            <span>Total</span>
                                            <span className="ml-auto text-red font-weight-bold">
                                                <b>
                                                    {selectedTotal.toLocaleString('en-US', {
                                                        style: 'currency',
                                                        currency: 'PKR',
                                                    })}
                                                </b>
                                            </span>
                                        </div>


              <br />
              <Button
                type="submit"
                className="btn-blue bg-red btn-lg btn-big"
              >
                <IoBagCheckOutline /> &nbsp; Submit Order
              </Button>

            </div>
          </div>
        </div>
      </form>

      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
      >
       
        <DialogContent>
        <div className=" d-flex align-items-center justify-content-center flex-column">
      <img src={thanks} width="350" />
    
  </div>  
         <h1 className="thankuh1">   Your order has been placed successfully.</h1>
         
         <div className="thankudialogeafterordersubmit ">
          <Link to="/"> <Button className='changestyleorderbtn respon colorbtnmy  btn-round'> Continue Shopping</Button></Link> 
          <Button  onClick={handleDialogClose} className=' colorbtnmy changestyleorderbtn respon btn-round'> Go TO Orders Page</Button> 
          </div> 

          
        </DialogContent>
       
      </Dialog>

    </div>
    </section>
    ) : (
      <div className="empty d-flex align-items-center justify-content-center flex-column">
      <img src={emprtCart} width="150" />
      <h3>Your Cart is currently empty</h3>
      <br />
      <Link to="/"> <Button className='btn-blue colorbtnmy btn-lg btn-big btn-round'><FaHome /> &nbsp; Continue Shopping</Button></Link>
  </div>
    )}
  </div>


   
  )
  
};

export default Checkout;
