
import React, { useEffect, useRef, useState } from "react";


import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import WhiteLogo from "../../assets/images/whitelogo.png";
import Button from "@mui/material/Button";
import CountryDropdown from "../CountryDropdown";
import { FiUser } from "react-icons/fi";
import { IoBagOutline } from "react-icons/io5";
import SearchBox from "./SearchBox";
import Navigation from "./Navigation";
import { useContext } from "react";
import { MyContext } from "../../App";
import { useLocation } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { FaClipboardCheck } from "react-icons/fa";
import { FaHeart } from "react-icons/fa";
import { RiLogoutCircleRFill } from "react-icons/ri";
import { FaUserAlt } from "react-icons/fa";
import { IoMdMenu } from "react-icons/io";
import { IoIosSearch } from "react-icons/io";
import { FaAngleLeft } from "react-icons/fa6";
import { MdOutlineLightMode } from "react-icons/md";
import { MdNightlightRound } from "react-icons/md";
const Header = () => {




  const [anchorEl, setAnchorEl] = useState(null);

  const [isOpenNav, setIsOpenNav] = useState(false);
  const [isOpenSearch, setIsOpenSearch] = useState(false);
  const open = Boolean(anchorEl);


  const headerRef = useRef();
  const context = useContext(MyContext);

  const history = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  


  const changeTheme = () => {
    if (context.theme === "dark") {
      context.setTheme("light");
    } else {
      context.setTheme("dark");
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
 
  const location = useLocation();

 
  const logout = () => {
    setAnchorEl(null);
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    context.setIsLogin(false);
  
    window.location.reload(); // Page reload karega
  };
  
  useEffect(() => {
    window.addEventListener("scroll", () => {
      let position = window.pageYOffset;
      if (headerRef.current) {
        if (position > 100) {
          headerRef.current.classList.add("fixed");
        } else {
          headerRef.current.classList.remove("fixed");
        }
      }
    });
  }, []);

  const openNav = () => {
    setIsOpenNav(!isOpenNav);
    context.setIsOpenNav(true);
  };





  const closeNav = () => {
    setIsOpenNav(false);
    context.setIsOpenNav(false);
  };

  const openSearch = () => {
    setIsOpenSearch(!isOpenSearch);
  };

  const closeSearch = () => {
    setIsOpenSearch(false);
  };
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const checkDarkMode = () => {
      setIsDarkMode(document.body.classList.contains("dark"));
    };

    // Initial check
    checkDarkMode();
    const observer = new MutationObserver(checkDarkMode);
    observer.observe(document.body, { attributes: true, attributeFilter: ["class"] });

    // Cleanup observer
    return () => observer.disconnect();
  }, []);
   
  const [timeLeft, setTimeLeft] = useState(3600); // 1 Hour Timer
  const [prevSeconds, setPrevSeconds] = useState(null);
  const [colorIndex, setColorIndex] = useState(0);
  const [animate, setAnimate] = useState(false);

  const colors = ["#FF5733", "#33FF57", "#3357FF", "#FF33A8", "#FFD700"]; // Random Colors

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev <= 0) {
          clearInterval(interval);
          return 0;
        }
        setPrevSeconds(prev % 60); // Previous second store karein
        setAnimate(true); // Animation Start
        setColorIndex((prevIndex) => (prevIndex + 1) % colors.length); // Color Change
        return prev - 1;
      });

      setTimeout(() => setAnimate(false), 500); // Animation Reset
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
    return { hours, minutes, seconds };
  };

  const { hours, minutes, seconds } = formatTime(timeLeft);

  return (
    <>
  <div className="top-strip">
      Limited Time Deal! Offer ends in
      <div className="timer">
        <div className="time-box">{hours}</div>
        <span>:</span>
        <div className="time-box">{minutes}</div>
        <span>:</span>
        <div className="time-box seconds-box">
          <div className={`prev ${animate ? "slide-out" : ""}`} style={{ backgroundColor: colors[(colorIndex - 1 + colors.length) % colors.length] }}>
            {prevSeconds}
          </div>
          <div className={`next ${animate ? "slide-in" : ""}`} style={{ backgroundColor: colors[colorIndex] }}>
            {seconds}
          </div>
        </div>
      </div>
    </div>

      <div className="headerWrapperFixed" ref={headerRef}>
        <div className="headerWrapper">
          <header className="header">
            <div className="container">
              <div className="row rowheadermain d-flex align-items-center ">
             
             
                <div className="logoWrapper d-flex align-items-center col-sm-1">
              
                  <Link to={"/"}>
                    {/* <img src={Logo} alt="Logo" /> */}
                    <img src={isDarkMode ? WhiteLogo : Logo} />
                    
                  </Link>
                </div>

                <div className="col-sm-10 d-flex align-items-center justify-content-space-between part2">
                  {context.countryList.length !== 0 &&
                    context.windowWidth > 992 && <CountryDropdown />}
                
               
                    <div className=" d-flex align-items-center mobilesearchbox darkbackground">
                      <SearchBox closeSearch={closeSearch} />
                    </div>
                 

                  <div className="part3  d-flex align-items-center ml-2">
                 

                   

                    {context.windowWidth < 992 && (
                      <>
                        <Button className="circle mr-3" onClick={changeTheme}>  
                        {context.theme === "light" ? (
    <>
     
        <MdNightlightRound />
     
    </>
  ) : (
    <>
    
        <MdOutlineLightMode />
     
    </>
  )}
                        </Button>
                      
                      </>
                    )}
                            {context.isLogin !== true && context.windowWidth > 992 && (
                      <Link to="/signIn">
                        <Button className="btn-blue btn-round mr-3">
                          Sign In
                        </Button>
                      </Link>
                    )}
                         {context.isLogin === true && context.windowWidth > 992 && (
                      <>
                        <Button className="circle mr-3" onClick={handleClick}>
                          <FiUser />
                        </Button>
                        <Menu
  anchorEl={anchorEl}
  id="accDrop"
  open={open}
  onClose={handleClose}
  onClick={handleClose}
  transformOrigin={{
    horizontal: "right",
    vertical: "top",
  }}
  anchorOrigin={{
    horizontal: "right",
    vertical: "bottom",
  }}
>
  <Link to="/my-account">
    <MenuItem onClick={handleClose}>
      <ListItemIcon>
        <FaUserAlt fontSize="small" />
      </ListItemIcon>
      My Account
    </MenuItem>
  </Link>
  <Link to="/orders">
    <MenuItem onClick={handleClose}>
      <ListItemIcon>
        <FaClipboardCheck fontSize="small" />
      </ListItemIcon>
      Orders
    </MenuItem>
  </Link>
  <Link to="/my-list">
    <MenuItem onClick={handleClose}>
      <ListItemIcon>
        <FaHeart fontSize="small" />
      </ListItemIcon>
      My List
    </MenuItem>
  </Link>

  <MenuItem onClick={changeTheme}>
{context.theme === "light" ? (
<>
<ListItemIcon>
<MdNightlightRound />
</ListItemIcon>
Dark Mode
</>
) : (
<>
<ListItemIcon>
<MdOutlineLightMode />
</ListItemIcon>
Light Mode
</>
)}
</MenuItem>
  <MenuItem>
    <ListItemIcon>
      <RiLogoutCircleRFill fontSize="small" />
    </ListItemIcon>
   <Link to="/about-us"> About Us </Link>
  </MenuItem>

  <MenuItem onClick={logout}>
    <ListItemIcon>
      <RiLogoutCircleRFill fontSize="small" />
    </ListItemIcon>
    Logout
  </MenuItem>

</Menu>
                      </>
                    )}

                    <div className="ml-auto cartTab d-flex align-items-center">
                      {context.windowWidth > 1000 && (
                        <span className="price">
                          {(context.cartData?.length !== 0
                            ? context.cartData
                                ?.map(
                                  (item) => parseInt(item.price) * item.quantity
                                )
                                .reduce((total, value) => total + value, 0)
                            : 0
                          )?.toLocaleString("en-US", {
                            style: "currency",
                            currency: "PKR",
                          })}
                        </span>
                      )}

                      <div className="position-relative">
                        <Link to="/cart">
                          <Button className="circle">
                            <IoBagOutline />
                          </Button>
                          {/* <span className='count d-flex align-items-center justify-content-center'>{context.cartData?.length>0 ? context.cartData?.length : 0}</span> */}
                          {context.cartData?.length > 0 && (
                            <span className="count d-flex align-items-center justify-content-center">
                              {context.cartData.length}
                            </span>
                          )}


                        </Link>
                   
                      </div>
                      <div className="logoWrapper d-flex align-items-center col-sm-1">
                <div className=" startmenu">
                {context.windowWidth < 992 && (
                        <Button
                          className="circle toggleNav"
                          onClick={openNav}
                        >
                          <IoMdMenu />
                        </Button>
                     )} 


                </div>
                 
                </div>
                
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>

          {context.categoryData?.length !== 0 && (
            <Navigation
              navData={context.categoryData}
              isOpenNav={isOpenNav}
              closeNav={closeNav}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Header;
