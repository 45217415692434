
// import React, { useEffect, useState } from 'react';
// import { useParams, useNavigate } from 'react-router-dom';
// import { fetchDataFromApi } from '../../utils/api';
// import Button from '@mui/material/Button';

// const OrderInfo = () => {
//     const { id } = useParams();
//     const [orderDetails, setOrderDetails] = useState(null);
//     const history = useNavigate();

//     useEffect(() => {
//         fetchDataFromApi(`/api/orders/${id}`).then((res) => {
//             setOrderDetails(res);
//         });
//     }, [id]);

//     return (

//         <div className='container'>
//              <h2 className="hd">Product Details</h2>
//              {orderDetails?.products?.map((product, index) => (
//             <div className="product-row d-flex justify-content-center" style={{  flexWrap: "wrap", gap: "20px", margin: "20px 0" }}>
//     {/* Left Section - Image */}
//     <div className="product-image" >
   
//                                              <img src={product?.image} alt={product?.productTitle} />
                                        
//     </div>

//     {/* Right Section - Details */}
//     <div className="product-details" style={{ flex: "3 1 300px" }}>
//         <p style={{ margin: "5px 0" }}><strong>Product Title:</strong> {product?.productTitle}</p>
//         {product?.size && product.size.trim() !== "" && (
//         <p style={{ margin: "5px 0" }}><strong>Color:</strong>{product?.color}</p>
//         )}
//          {product?.size && product.size.trim() !== "" && (
//         <p style={{ margin: "5px 0" }}><strong>Size:</strong> {product?.size}</p>
//          )}
//     </div>
// </div>


//              ))}
//         {orderDetails?.products?.map((product, index) => (

   
// <div className="product-details-showmore" >
//     <table>
//         <tr>
//             <th>Product ID</th>
//             <td>{product?.id}</td>
//         </tr>
//         <tr>
//             <th>Quantity</th>
//             <td>{product?.quantity}</td>
//         </tr>
//         <tr>
//             <th>Price</th>
//             <td>{product?.price}</td>
//         </tr>
//         <tr>
//             <th>Shipping</th>
//             <td>Free Shipping</td>
//         </tr>
//         <tr>
//             <th>Total</th>
//             <td>{product?.subTotal}</td>
//         </tr>
//     </table>

//     <div>
//     <h1 className="hd mt-5">Shipping Details</h1>
//   <table>
//     <tr>
//         <th> Shipped By </th>
//         <td> TCS  </td>
//     </tr>
//     <tr>
//         <th> Tracking ID </th>
//         <td> 0000000000  </td>
//     </tr>
//     <tr>
//         <th> Adress </th>
//         <td> {orderDetails?.address}  </td>
//     </tr>
//     <tr>
//         <th> Phone </th>
//         <td> {orderDetails?.phone}  </td>
//     </tr>
//     <tr>
//         <th> Email </th>
//         <td> {orderDetails?.email}  </td>
//     </tr>
//     <tr>
//         <th> Status </th>
//         <td> {orderDetails?.status}  </td>
//     </tr>
//   </table>

//     </div>
// </div>
             
            
                           
                            
//                         ))}
//         </div>
    

//     );
// };

// export default OrderInfo;


import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchDataFromApi } from '../../utils/api';
import Button from '@mui/material/Button';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Tooltip, IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const OrderInfo = () => {
    const { id } = useParams();
    const [orderDetails, setOrderDetails] = useState(null);
    const history = useNavigate();

    useEffect(() => {
        fetchDataFromApi(`/api/orders/${id}`).then((res) => {
            setOrderDetails(res);
        });
    }, [id]);

    // Function to generate PDF
    // const generatePDF = () => {
    //     const content = document.getElementById('pdf-content'); // Target the content
    //     html2canvas(content).then((canvas) => {
    //         const imgData = canvas.toDataURL('image/png');
    //         const pdf = new jsPDF();
    //         const imgWidth = 190; // Adjust width for PDF
    //         const pageHeight = 297; // A4 Page height in mm
    //         const imgHeight = (canvas.height * imgWidth) / canvas.width;
    //         let heightLeft = imgHeight;

    //         let position = 0;
    //         pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
    //         heightLeft -= pageHeight;

    //         while (heightLeft >= 0) {
    //             position = heightLeft - imgHeight;
    //             pdf.addPage();
    //             pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
    //             heightLeft -= pageHeight;
    //         }

    //         pdf.save('OrderDetails.pdf'); // Save the PDF
    //     });
    // };


    const generatePDF = () => {
        const content = document.getElementById('pdf-content'); // Target the content
        html2canvas(content, {
            scale: 2, // High-quality rendering
            useCORS: true, // Handle cross-origin images
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const pageWidth = pdf.internal.pageSize.getWidth();
            const pageHeight = pdf.internal.pageSize.getHeight();
            const imgWidth = pageWidth - 20; // 10mm padding on each side
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
    
            // Centering the content
            const x = (pageWidth - imgWidth) / 2;
            const y = (pageHeight - imgHeight) / 2;
    
            pdf.addImage(imgData, 'PNG', x, y, imgWidth, imgHeight);
            pdf.save('OrderDetails.pdf');
        });
    };
    
    // Function to copy tracking ID
    const copyTrackingID = (trackingID) => {
        navigator.clipboard.writeText(trackingID);
        alert('Tracking ID copied!');
    };
    const products = orderDetails?.products 
    ? Array.isArray(JSON.parse(orderDetails.products)) 
        ? JSON.parse(orderDetails.products) 
        : [JSON.parse(orderDetails.products)]
    : [];

    return (
        <div className='container'>
            <h2 className="hd">Product Details</h2>
            <div id="pdf-content"> {/* Wrap content for PDF generation */}
                {/* {orderDetails?.products?.map((product, index) => (
                    <div key={index} className="product-row d-flex justify-content-center" style={{ flexWrap: "wrap", gap: "20px", margin: "20px 0" }}>
                        <div className="product-image">
                            <img src={product?.image} alt={product?.productTitle} />
                        </div>
                        <div className="product-details" style={{ flex: "3 1 300px" }}>
                            <p style={{ margin: "5px 0" }}><strong>Product Title:</strong> {product?.productTitle}</p>
                            {product?.color && (
                                <p style={{ margin: "5px 0" }}><strong>Color:</strong> {product?.color}</p>
                            )}
                            {product?.size && product.size.trim() !== "" && (
                                <p style={{ margin: "5px 0" }}><strong>Size:</strong> {product?.size}</p>
                            )}
                        </div>
                    </div>
                ))} */}
                {Array.isArray(products) && products.map((product, index) => (
    <div key={index} className="product-row d-flex justify-content-center" style={{ flexWrap: "wrap", gap: "20px", margin: "20px 0" }}>
        <div className="product-image">
            <img src={product?.image} alt={product?.productTitle} width={"300px"} height={"300px"} />
        </div>
        <div className="product-details" style={{ flex: "3 1 300px" }}>
            <p style={{ margin: "5px 0" }}><strong>Product Title:</strong> {product?.productTitle}</p>
            {product?.color && (
                <p style={{ margin: "5px 0" }}><strong>Color:</strong> {product?.color}</p>
            )}
            {product?.size && product.size.trim() !== "" && (
                <p style={{ margin: "5px 0" }}><strong>Size:</strong> {product?.size}</p>
            )}
        </div>
    </div>
))}
                {/* {orderDetails?.products?.map((product, index) => (
                    <div key={index} className="product-details-showmore">
                        <table>
                            <tr>
                                <th>Product ID</th>
                                <td>{product?.id}</td>
                            </tr>
                            <tr>
                                <th>Quantity</th>
                                <td>{product?.quantity}</td>
                            </tr>
                            <tr>
                                <th>Price</th>
                                <td>{product?.price}</td>
                            </tr>
                            <tr>
                                <th>Shipping</th>
                                <td>Free Shipping</td>
                            </tr>
                            <tr>
                                <th>Total</th>
                                <td>{product?.subTotal}</td>
                            </tr>
                        </table>
                        <div>
                            <h1 className="hd mt-5">Shipping Details</h1>
                            <table>
                                <tr>
                                    <th> Shipped By </th>
                                    <td> TCS </td>
                                </tr>
                                <tr>
                                    <th> Tracking ID </th>
                                    <td>
                                        0000000000
                                       
                                    
                                    </td>
                                </tr>
                                <tr>
                                    <th> Order ID </th>
                                    <td>
                                    {orderDetails?.id}
                                       
                                    
                                    </td>
                                </tr>
                                <tr>
                                    <th> Address </th>
                                    <td> {orderDetails?.address} </td>
                                </tr>
                                <tr>
                                    <th> Phone </th>
                                    <td> {orderDetails?.phone} </td>
                                </tr>
                                <tr>
                                    <th> Email </th>
                                    <td> {orderDetails?.email} </td>
                                </tr>
                                <tr>
                                    <th> Status </th>
                                    <td> {orderDetails?.status} </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                ))} */}

{products.map((product, index) => (
    <div key={index} className="product-details-showmore">
        <table>
            <tbody>
                <tr>
                    <th>Product ID</th>
                    <td>{product?.productId}</td>
                </tr>
                <tr>
                    <th>Quantity</th>
                    <td>{product?.quantity}</td>
                </tr>
                <tr>
                    <th>Price</th>
                    <td>{product?.price}</td>
                </tr>
                <tr>
                    <th>Shipping</th>
                    <td>Free Shipping</td>
                </tr>
                <tr>
                    <th>Total</th>
                    <td>{product?.subTotal}</td>
                </tr>
            </tbody>
        </table>
        <div>
            <h1 className="hd mt-5">Shipping Details</h1>
            <table>
                <tbody>
                    <tr>
                        <th>Shipped By</th>
                        <td>TCS</td>
                    </tr>
                    <tr>
                        <th>Tracking ID</th>
                        <td>0000000000</td>
                    </tr>
                    <tr>
                        <th>Order ID</th>
                        <td>{orderDetails?.id}</td>
                    </tr>
                    <tr>
                        <th>Address</th>
                        <td>{orderDetails?.address}</td>
                    </tr>
                    <tr>
                        <th>Phone</th>
                        <td>{orderDetails?.phone}</td>
                    </tr>
                    <tr>
                        <th>Email</th>
                        <td>{orderDetails?.email}</td>
                    </tr>
                    <tr>
                        <th>Status</th>
                        <td>{orderDetails?.status}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
))}

            </div>
            <Button variant="contained" color="primary" onClick={generatePDF} style={{ marginTop: '20px' }}>
                Download PDF
            </Button>
        </div>
    );
};

export default OrderInfo;
