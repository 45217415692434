import "./countdown.css"

export default function CountdownPage() {

 

  return (
  <>

    <main>
        <div class="content">
            <h2>Matt D’Avella</h2>
            <h1>It’s time to slow down.</h1>
            <div class="countdown">
                <div class="timer">
                    <span class="number">07</span>
                    <span class="label">DAYS</span>
                </div>
                <div class="timer">
                    <span class="number">10</span>
                    <span class="label">HOURS</span>
                </div>
                <div class="timer">
                    <span class="number">54</span>
                    <span class="label">MINUTES</span>
                </div>
            </div>
            <div class="buttons">
                <button class="btn btn-primary">READ MORE</button>
                <button class="btn btn-secondary">BOOK NOW</button>
            </div>
        </div>
    </main>

    <footer>
        <p>#dailyui #dailyuichallenge</p>
    </footer>
  </>
  );
}
