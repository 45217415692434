import Button from "@mui/material/Button";
import { IoIosMenu } from "react-icons/io";
import { FaAngleDown } from "react-icons/fa6";
import { json, Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { FaAngleRight } from "react-icons/fa6";
import { MyContext } from "../../../App";
import CountryDropdown from "../../CountryDropdown";
import { FaUserAlt } from "react-icons/fa";
import { FaClipboardCheck } from "react-icons/fa";
import { FaHeart } from "react-icons/fa";
import { RiLogoutCircleRFill } from "react-icons/ri";
import {
  fetchDataFromApi,

} from "../../../utils/api";
import NoUserImg from "../../../assets/images/no-user.jpg";

const Navigation = (props) => {
  const [isopenSidebarVal, setisopenSidebarVal] = useState(false);
  const [isOpenNav, setIsOpenNav] = useState(false);
  const [isOpenSubMenuIndex, setIsOpenSubMenuIndex] = useState(null);
  const [isOpenSubMenu_, setIsOpenSubMenu_] = useState(false);
  const [subCatData, setSubCatData] = useState([]);


useEffect(() => {
  window.scrollTo(0, 0);
  fetchDataFromApi('/api/subcat').then((res) => {
      if (res?.length) {
          setSubCatData(res); // Subcategory data ko state mein set karo
      }
  });

}, []);


    const [formFields, setFormFields] = useState(false);
  
    const [images, setImages] = useState([]);
  
  
  
  
    const context = useContext(MyContext);
  
  
  
   

    

  useEffect(() => {
    try {
        // LocalStorage se data retrieve karna
        const storedFormFields = localStorage.getItem("formFields");
        const storedImages = localStorage.getItem("userImages");

        const parsedImages = storedImages ? JSON.parse(storedImages) : null;
        // Safely parse karne ke liye check karna
        const parsedFormFields = storedFormFields ? JSON.parse(storedFormFields) : null;

        if (parsedImages) {
            setImages([parsedImages]);
        }

        if (parsedFormFields) {
            setFormFields(parsedFormFields);
        }


    } catch (error) {
        console.error("Error parsing localStorage data:", error);
    }
}, []);

  useEffect(() => {
    setIsOpenNav(props.isOpenNav);
  }, [props.isOpenNav]);

  const IsOpenSubMenu = (index) => {
    setIsOpenSubMenuIndex(index);
    setIsOpenSubMenu_(!isOpenSubMenu_);
  };
  const logout = () => {
    
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    context.setIsLogin(false);
  
    window.location.reload(); // Page reload karega
  };
  return (
  <>
    <nav className="none-display">
      <div className="container">
        <div className="row">
          <div className="col-sm-2 navPart1 ">
            <div className="catWrapper">
              <Button
                className="allCatTab colorbtnmy align-items-center res-hide"
                onClick={() => setisopenSidebarVal(!isopenSidebarVal)}
              >
                <span className="icon1 mr-2">
                  <IoIosMenu />
                </span>
                <span className="text ">ALL CATEGORIES</span>
                <span className="icon2  ml-2">
                  <FaAngleDown />
                </span>
              </Button>

              <div
                className={`sidebarNav ${isopenSidebarVal === true ? "open" : ""
                  }`}
              >
                <ul>
                  {props.navData?.map((item, index) => {
                    return (
                      <li key={index}>
                        <Link to={`/products/category/${item?.id}`}>
                          <Button>
                            {item?.name} <FaAngleRight className="ml-auto" />
                          </Button>
                        </Link>
                        {subCatData?.length !== 0 && (
                          <div className="submenu">
                            {subCatData?.map((subCat, key) => {
                              return (
                                <Link
                                  to={`/products/subCat/${subCat?.id}`}
                                  key={key}
                                >
                                  <Button>{subCat?.name}</Button>
                                </Link>
                              );
                            })}
                          </div>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>

          <div
            className={`col-sm-10 navPart2 d-flex align-items-center res-nav-wrapper ${isOpenNav === true ? "open" : "close"
              }`}
          >
            <div className="res-nav-overlay" onClick={props.closeNav}></div>
 
            <ul className="list list-inline ml-auto res-nav">
            {context.windowWidth < 992 && context.isLogin === true && (
               <div className="frontpartoful">
                <li className="list-inline-item mb-4 topchangecolor mt-2">
    <div className="sidebarmaindiv">
  
   <div className="sidenavmainimg">
{/* {images.length > 0 ? (
  JSON.parse(images[0]).map((img, index) => (
    <img src={img} key={index} alt={`Image ${index}`} />
  ))
) : (
  <img src={NoUserImg} style={{ width: "80px" }} alt="No User" />
)} */}
{images.length > 0 ? (
  <img src={images[0].replace(/^"|"$/g, "")} alt="User Image" />
) : (
  <img src={NoUserImg} style={{ width: "80px" }} alt="No User" />
)}
   </div>

   {formFields && formFields.name && formFields.email && (
  <div className="sidebarusercontent"> 
    <p className="usernamemain">{formFields.name}</p>
    <p className="emailinnavbar">
      Email: <span className="useremailmain">
        {formFields.email.length > 15 ? formFields.email.slice(0, 15) + "..." : formFields.email}
      </span>
    </p>
  </div>
)}
     </div>
  
   </li>
                  
                 </div>
            )}
              {context.windowWidth < 992 && (
                <>
                  {context.isLogin !== true && (
                    <li className="list-inline-item mt-3 pl-3">
                      <Link to="/signIn">
                        <Button className="btn-blue btn-round mr-3">
                          Sign In
                        </Button>
                      </Link>
                    </li>
                  )}

{context.isLogin === true && (
            <div>
               <li className="list-inline-item menuliststyle mt-2">

<Link to="/my-account">
  <Button onClick={props.closeNav}>

    <FaUserAlt fontSize="small" />

    <span className=""> &nbsp; My Account</span>  
  </Button>
</Link>

</li>

<li className="list-inline-item menuliststyle mt-1">

<Link to="/orders">

  <Button onClick={props.closeNav}>

    <FaClipboardCheck fontSize="small" />

    <span className=""> &nbsp; Orders</span>
  </Button>

</Link>

</li>

<li className="list-inline-item menuliststyle mt-1">

<Link to="/my-list">

<Button onClick={props.closeNav}>

<FaHeart fontSize="small" />

<span className=""> &nbsp; My List</span>
</Button>

</Link>

</li>
            </div>
)}

     

                </>
              )}

              {props.navData
                .filter((item, idx) => idx < 8)
                .map((item, index) => {
                  return (
                    <li key={index} className="list-inline-item">
                      <Link
                        to={`/products/category/${item?.id}`}
                        onClick={props.closeNav}
                      >
                        <Button>{item?.name}</Button>
                      </Link>

                      {item?.children?.length !== 0 &&
                        context.windowWidth < 992 && (
                          <span className={`arrow ${isOpenSubMenuIndex === index &&
                            isOpenSubMenu_ === true &&
                            "rotate"
                            }`}
                            onClick={() => IsOpenSubMenu(index)}
                          >
                            <FaAngleDown />
                          </span>
                        )}

                      {item?.children?.length !== 0 && (
                        <div
                          className={`submenu ${isOpenSubMenuIndex === index &&
                            isOpenSubMenu_ === true &&
                            "open"
                            }`}
                        >
                          {item?.children?.map((subCat, key) => {
                            return (
                              <Link
                                to={`/products/subCat/${subCat?.id}`}
                                key={key}
                                onClick={props.closeNav}
                              >
                                <Button>{subCat?.name}</Button>
                              </Link>
                            );
                          })}
                        </div>
                      )}
                    </li>

                  );
                 })}
                 {context.windowWidth < 992 && (
<li className="list-inline-item menuliststyle logoutfixed mt-1">



{
  context.isLogin &&  <Button onClick={logout}>

  <RiLogoutCircleRFill fontSize="small" />

<span className=""> &nbsp; Logout</span>
</Button>
}



</li>
                 )}
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </>
  );
};

export default Navigation;
