import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { IoMdCloudUpload } from "react-icons/io";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useNavigate , Link } from "react-router-dom";
import {
  deleteData,
  deleteImages,
  editData,
  fetchDataFromApi,
  postData,
  uploadImage,
} from "../../utils/api";

import { MyContext } from "../../App";

import NoUserImg from "../../assets/images/no-user.jpg";
import CircularProgress from "@mui/material/CircularProgress";


const MyAccount = () => {
  const [isLogin, setIsLogin] = useState(false);
  const [formFields, setFormFields] = useState(false);

  const [value, setValue] = React.useState(0);


  const history = useNavigate();

  const context = useContext(MyContext);

  const [isLoading, setIsLoading] = useState(false);
  const [uploading, setUploading] = useState(false);

  const [previews, setPreviews] = useState([]);
  const [userData, setUserData] = useState([])

  
  useEffect(() => {
    window.scrollTo(0, 0);

    const token = localStorage.getItem("token");
    if (token !== "" && token !== undefined && token !== null) {
      setIsLogin(true);
    } else {
      history("/signIn");
    }

    deleteData("/api/imageUpload/deleteAllImages");
    const user = JSON.parse(localStorage.getItem("user"));
    fetchDataFromApi(`/api/user/${user?.userId}`).then((res) => {
      setUserData(res);
      setPreviews(res.images.replace(/"/g, '').split(','));
      localStorage.setItem("userImages", JSON.stringify(res.images));
      setFormFields({
       
        name: res.name.toUpperCase(),
        email: res.email,
        phone: res.phone,
        country:res.country,
        city:res.city,
        state:res.state,
        zipCode:res.zipCode,
        streetAddressLine1:res.streetAddressLine1,
        streetAddressLine2:res.streetAddressLine2
      });
    });

   
   
  }, []);
  localStorage.setItem("formFields", JSON.stringify(formFields));

  return (
    <section className="sectionper">
    <div class="main-containerper">
    <div className="userImage d-flex align-items-center justify-content-center">
                  {uploading === true ? (
                    <CircularProgress />
                  ) : (
                     <>
                      {previews?.length !== 0 ? (
    previews?.map((img, index) => {
        // ✅ Square brackets remove karne ke liye replace use karein
        const cleanImgUrl = img.replace(/[\[\]]/g, "");  
        return <img src={cleanImgUrl} key={index} alt="userImage" />;
    })
) : (
                       <img src={NoUserImg} alt="NouserImage"/>
                     )}
                     <div className="overlay d-flex align-items-center justify-content-center">
                          
                          
                     </div>
                   </>
                 )}
               </div>
        <div class="content">
            <h2><span>{formFields.name}</span></h2>
            {/* <h1>Hi .. <span>{formFields.name.length > 9 ? formFields.name.slice(0, 11) + '..' : formFields.name}</span></h1> */}

            <h4>Email : <span>{formFields.email}</span></h4>
            <h4>Phone : <span>{formFields.phone}</span></h4>
           <address>
           <h4>City : <span>{formFields.city}</span></h4>
           <h4>Country : <span>{formFields.country}</span></h4>
           <h4>Zipcode : <span>{formFields.zipCode}</span></h4>
           <h4>State : <span>{formFields.state}</span></h4>
           <h4>Address : <span>{formFields.streetAddressLine1 + " " + formFields.streetAddressLine2}</span></h4>

           </address>
    
            <div class="btnper">
               <Link to="/userinfo">
               <Button>Edit Your Profile</Button>
               </Link>
            </div>
        </div>
    </div>
</section>


  );
};

export default MyAccount;
