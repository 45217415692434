

const AboutUs = ()=>{
    return(
        
        <>
        <div class="container">
        <h1>About Us & Policies</h1>

        <div class="section" id="about-us">
            <h2>About Us</h2>
            <p>Welcome to Wonzilla Shopping Store, your one-stop destination for high-quality products including handbags, AirPods, sunglasses, and much more. Our mission is to provide an exceptional shopping experience with a focus on customer satisfaction, quality, and reliability. We believe in offering products that not only meet but exceed your expectations.</p>
        </div>

        <div class="section" id="privacy-policy">
            <h2>Privacy Policy</h2>
            <p>At Wonzilla Shopping Store, we respect your privacy and are committed to protecting your personal information. We collect your data solely for the purpose of improving your shopping experience and fulfilling your orders. We do not share or sell your information to third parties without your consent. For detailed information, please contact our support team.</p>
        </div>

        <div class="section" id="refund-policy">
            <h2>Refund Policy</h2>
            <p>We strive to ensure your satisfaction with every purchase. If you are not satisfied with your order, you may request a refund within 7 days of receiving the product. The product must be returned in its original condition and packaging. Refunds will be processed within 5-7 business days after the returned item is received and inspected.</p>
            <p>Please note that some items, such as personalized products, may not be eligible for a refund. Shipping costs are non-refundable unless the return is due to an error on our part.</p>
        </div>

        <div class="section" id="terms-and-conditions">
            <h2>Terms and Conditions</h2>
            <p>By using our website, you agree to abide by our terms and conditions. This includes providing accurate information during checkout, refraining from unauthorized use of our content, and respecting our intellectual property rights. Any disputes arising from your use of our services will be resolved under applicable laws.</p>
        </div>

        <div class="section" id="contact-us">
            <h2>Contact Us</h2>
            <p>If you have any questions about our policies or your orders, feel free to contact us at support@wonzilla.com or call us at +123-456-7890. We are here to help!</p>
        </div>
    </div>
    </>
    )
    
}
export default AboutUs;