import { LuShirt } from "react-icons/lu";
import { TbTruckDelivery } from "react-icons/tb";
import { TbDiscount2 } from "react-icons/tb";
import { CiBadgeDollar } from "react-icons/ci";
import { Link } from "react-router-dom";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import newsLetterImg from "../../assets/images/newsletter.png";
import Button from "@mui/material/Button";
import { IoMailOutline } from "react-icons/io5";
import whatsapplogo from "../../assets/images/mywtspp.png";
import whatsapplogodark from "../../assets/images/whatsapp.png";
import Banners from "../banners/index";
import { useEffect, useState } from "react";
import { colors } from "@mui/material";
import { fetchDataFromApi, postData, deleteData } from "../../utils/api";
import { useContext , useRef } from "react";
import { MyContext } from "../../App";
const Footer = () => {
  // const [email, setEmail] = useState("");
  const [isSubscribedEmail, setIsSubscribedEmail] = useState(false);

  // Check if user is already subscribed from localStorage
  useEffect(() => {
    const emailSubscribed = localStorage.getItem("emailsubscribed");
    if (emailSubscribed) {
      setIsSubscribedEmail(true);
    }
  }, []);

  const [shake, setShake] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setShake(true); // Add shake animation
      setTimeout(() => {
        setShake(false); // Remove shake animation after 500ms
      }, 500); // Match this duration with CSS animation time
    }, 2000); // Trigger every 2 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);




   const context = useContext(MyContext);
  const [bannerList, setBannerList] = useState([]);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false); 



  const adduseremail = async (e) => {
    e.preventDefault();
    setIsLoading(true);
   
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      fetchDataFromApi(`/api/user/${user?.userId}`).then((res) => {
        if(email == res.email){
          postData("/api/usersemails/create", { email }).then((res) => {
            setIsLoading(false);
            if (res.success) {
              // Success: Email added
              setIsSubscribed(true); // Set subscription status to true
              setMessage(res.message);
              setEmail(""); // Clear input field
              localStorage.setItem("emailsubscribed", true);
              setIsSubscribedEmail(true);
              context.setAlertBox({
                open: true,
                error: false,
                msg: res.message,
              });
            } else {
              // Error: Email already exists or other error
              setMessage(res.message);
              context.setAlertBox({
                open: true,
                error: true,
                msg: res.message,
              });
            }
          });
        } else{
          context.setAlertBox({
            open: true,
            error: true,
            msg: "please enter your email address",
          });
        }
      });
    
    } catch (error) {
      setIsLoading(false);
      setMessage("Error: Could not subscribe. Please try again.");
      context.setAlertBox({
        open: true,
        error: true,
        msg: "Invalid Email",
      });
    }
  };
  


  document.addEventListener("DOMContentLoaded", () => {
    const whatsappIcon = document.querySelector(".whatsapp-icon img");

    setInterval(() => {
        whatsappIcon.classList.add("shake");

        // Remove the animation class after it completes
        setTimeout(() => {
            whatsappIcon.classList.remove("shake");
        }, 500); // Match this time with the animation duration in CSS
    }, 2000); // Trigger the animation every 2 seconds
});

  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const checkDarkMode = () => {
      setIsDarkMode(document.body.classList.contains("dark"));
    };

    // Initial check
    checkDarkMode();
    const observer = new MutationObserver(checkDarkMode);
    observer.observe(document.body, { attributes: true, attributeFilter: ["class"] });

    // Cleanup observer
    return () => observer.disconnect();
  }, []);
  
  return (
    <>
      <div className="container">
        
      </div>
      <section className="newsLetterSection mt-3 mb-3 d-flex align-items-center">
        <div  className="container bg-none" >
          <div className="row">
            <div className="col-md-6">
              
            <h1 className="text-white "> <p className="text-white mb-1">  Wonzilla Just For You </p>  </h1> 
             
              <h6  >Join our newsletter and receive daily updates on the best products</h6>
              <p className="text-light">
                Join our email subscription now to get updates on
                <br /> promotions and coupons.
              </p>

<div className="responsiveform">
      { localStorage.getItem("emailsubscribed") ? (
        // Show message if subscribed or localStorage has emailsubscribed
        <h3 className=" text-white mt-3">
        Thank you! You have successfully joined our newsletter.
      </h3>
      ) : (
        // Show form if not subscribed
        <form onSubmit={adduseremail}>
          <IoMailOutline />
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <Button type="submit" className="colorbtnmy">
            Join
          </Button>
        </form>
      )}

  
    </div>
            </div>

            <div className="col-md-6">
              <img src={newsLetterImg} />
            </div>
          </div>
        </div>
      </section>



      <footer>
        <div className="container">
          <div className="topInfo row">
            <div className="col d-flex align-items-center">
              <span>
                <LuShirt />
              </span>
              <span className="ml-2">Everyday fresh products</span>
            </div>

            <div className="col d-flex align-items-center">
              <span>
                <TbTruckDelivery />
              </span>
              <span className="ml-2">Free delivery for order over $70</span>
            </div>

            <div className="col d-flex align-items-center">
              <span>
                <TbDiscount2 />
              </span>
              <span className="ml-2">Daily Mega Discounts</span>
            </div>

            <div className="col d-flex align-items-center">
              <span>
                <CiBadgeDollar />
              </span>
              <span className="ml-2">Best price on the market</span>
            </div>
          </div>

          <div className="row mt-5 linksWrap">
            <div className="col">
              <h5>FRUIT & VEGETABLES</h5>
              <ul>
                <li>
                  <Link to="#">Fresh Vegetables</Link>
                </li>
                <li>
                  <Link to="#">Herbs & Seasonings</Link>
                </li>
                <li>
                  <Link to="#">Fresh Fruits</Link>
                </li>
                <li>
                  <Link to="#">Cuts & Sprouts</Link>
                </li>
                
              </ul>
            </div>

      
          </div>

          <div className="copyright mt-3 pt-3 pb-3 d-flex">
            <p className="mb-0">Copyright 2024. All rights reserved</p>
            <ul className="list list-inline ml-auto mr-5 mt-2 mb-0 socials">
              <li className="list-inline-item">
              <Link to="https://www.facebook.com/profile.php?id=61565962113635" target="_blank" rel="noopener noreferrer">
  <FaFacebookF />
</Link>

              </li>

              <li className="list-inline-item">
                <Link to="#">
                  <FaTwitter />
                </Link>
              </li>

              <li className="list-inline-item">
                <Link to="https://www.instagram.com/wonzilla_/profilecard/?igsh=b2NwZjQwZWxucHJo" target="_blank" rel="noopener noreferrer">
                  <FaInstagram />
                </Link>
              </li>
             


      </ul>
<div className="whatsapp-icon">
      <a href="https://wa.me/03157847558" target="_blank" rel="noopener noreferrer">
        <img
          src={ whatsapplogo} 
          alt="WhatsApp"
          className={`${shake ? "shake" : ""} whatsapp-icon-image`}
        />
         
      </a>
    </div>

          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
